import { Navbar, Container, Nav } from "react-bootstrap";
import { useState, useEffect, useContext } from "react";
import navIcon1 from '../assets/img/github.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';
import './styles/NavBar.css';
import AuthContext from "../context/AuthContext";
import NavigationContext from "../context/NavigationContext";
import { useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faGears } from "@fortawesome/free-solid-svg-icons";


export default function NavBar() {
    const navigate = useNavigate();
    // get auth data from AuthContext
    const {user, setAuthTokens, setUser} = useContext(AuthContext);
    // state that store the libk we're on actually
    const {activeLink, setActiveLink} = useContext(NavigationContext);
    // state that stores wether the user scrolled or not
    const [hasScrolled, setHasScrolled] = useState(false);
    // hook that will be triggered each time the user scrolls
    useEffect(() => {
        const onScroll = () => {
            if(window.scrollY > 50 ){
                setHasScrolled(true);
            }else {
                setHasScrolled(false);
            }
        };
        // add this function as callback to eventListener for the scroll event on the window
        window.addEventListener("scroll", onscroll);
        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const onUpdateActiveLink = (link) => {
        if(activeLink === 'admin' && !user){
            navigate('/');
            console.log('passed here');
        }
        console.log('clicked ' + link);
        setActiveLink(link);
        localStorage.setItem('navigation', link);
    };
   
    // on load of the page set the navlink to 'home'
    window.addEventListener('load', () => {
        if(localStorage.getItem('navigation') !== null){
            if(window.location.href.includes('#')){
                setActiveLink(localStorage.getItem('navigation'));
            }else if (window.location.href.includes('/projects')){
                localStorage.setItem('navigation', 'projects');
                setActiveLink('projects');
            }
            else{
                if(user){
                    localStorage.setItem('navigation', 'home');
                    setActiveLink('home');
                }else{
                    setActiveLink(localStorage.getItem('navigation'));
                }
            }
        }else{
            setActiveLink('home');
            localStorage.setItem('navigation', 'home');
        }
    });

    // function that handles logout
    const handleLogout = () => {
        setUser(null);
        setAuthTokens(null);
        setActiveLink('admin');
        localStorage.setItem('navigation', 'admin');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };

    return (
        <Navbar bg="dark" expand="lg" className={hasScrolled ? "scrolled" : ""}>
            <Container>
                <Navbar.Brand href="/" onClick={() => {
                    setActiveLink('home');
                    localStorage.setItem('navigation', 'home');
                    }}> Amine MAOURID </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" >
                    <span className="navbar-toggler-icon"></span>
                </Navbar.Toggle>
                {
                    user ? 
                    (
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="/" 
                                className={activeLink === 'home' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('home')} >Home</Nav.Link>
                                <Nav.Link href ="/skills" 
                                className={activeLink === 'skills' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('skills')}>
                                    <FontAwesomeIcon icon={faGear} spin style={{position : "relative", marginRight : "4px", color : "#fff"}} size="lg" />
                                    Skills
                                </Nav.Link>
                                <Nav.Link href="/projects" 
                                className={activeLink === 'projects' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('projects')}>
                                    <FontAwesomeIcon icon={faGear} spin style={{position : "relative", marginRight : "4px", color : "#fff"}} size="lg" />
                                    Projects
                                </Nav.Link>
                            </Nav>
                            <span className="navbar-text">
                                <div className="social-icon">
                                    <a href="https://github.com/amineenim"> <img src={navIcon1} alt="github-link" /> </a>
                                    <a href="#"> <img src={navIcon2} alt="" /> </a>
                                    <a href="#"> <img src={navIcon3} alt="" /> </a>
                                </div>
                                <button className="contact-us-button" onClick={handleLogout}>
                                    <span>Logout</span>
                                </button>
                            </span>
                        </Navbar.Collapse>
                    ) : 
                    (
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="#banner" 
                                className={activeLink === 'home' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('home')} >Home</Nav.Link>
                                <Nav.Link href ="#skills" 
                                className={activeLink === 'skills' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('skills')}>Skills</Nav.Link>
                                <Nav.Link href="#projects" 
                                className={activeLink === 'projects' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('projects')}>Projects</Nav.Link>
                                <Nav.Link href="#stars" 
                                className={activeLink === 'stars' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('stars')}>Rate Me</Nav.Link>
                                <Nav.Link href="/admin" 
                                className={activeLink === 'admin' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('admin')}>Admin</Nav.Link>
                                <Nav.Link href="#connect"
                                className={activeLink === 'connect' ? "active navbar-link" : "navbar-link"}
                                onClick={() => onUpdateActiveLink('connect')}>Reach Me</Nav.Link>
                            </Nav>
                            <span className="navbar-text">
                                <div className="social-icon">
                                    <a href="https://github.com/amineenim"> <img src={navIcon1} alt="github-link" /> </a>
                                    <a href="https://www.facebook.com/amine.maourid/"> <img src={navIcon2} alt="" /> </a>
                                    <a href="https://www.instagram.com/amine_maourid/"> <img src={navIcon3} alt="" /> </a>
                                </div>
                            </span>
                        </Navbar.Collapse>
                    )
                }
                
            </Container>
        </Navbar>
    );
};