import React, { useEffect, useContext, useState } from 'react'
import './styles/ProjectDetail.css';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { HomeAndProjectsContext } from './App';
import GoBackButton from './GoBackButton';
import baseURL from '../config';

function ProjectDetail() {
    // get all projects from context data
    const {projects, setErrors, message} = useContext(HomeAndProjectsContext);
    // grab the id of the project from URL 
    const {id} = useParams();
    // state that stores the project to display
    const [project, setProject] = useState(null);
    // state that stores whether data is loading or not
    const [isLoading, setIsLoading] = useState(true);


    // function that handles getting the project to display when the component renders
    useEffect(() => {
        const getProject = (project_id) => {
            if (projects.length === 0 && !message){
                setIsLoading(true);
            }else{
                setIsLoading(false);
                if(projects.length > 0){
                    const searched_project = projects.filter((pj) =>  pj.id === project_id);
                    if(searched_project.length > 0){
                        setProject(searched_project[0]);
                        setErrors([]);
                    }else {
                        setErrors([{'status' : 404, 'detail' : 'No Project corresponds to the given query parameter of value '+ id}]);
                    }
                }
            }
        };
        getProject(parseInt(id, 10));

    },[id, projects]);

  return (
    <>
    {   isLoading ? 
        (
            <div className='loading'>Loading data</div>
        ) :
        (project ? (
            <>
                <Col sm={12} className='project-detail-header'>
                    <h3>Project <span>{project.title}</span> details</h3>
                    <GoBackButton text="Go Back to all Projects" targetsWhat='projects' />
                </Col>
                <Col sm={12} md={6} className='project-detail-img'>
                    <img src={`${baseURL}${project.image}`} alt='project-bg-img' />
                </Col>
                <Col sm={12} md={6} className='project-detail-right'>
                    <section>
                        <h4> {project.title} </h4>
                        <p>
                            {project.description}
                        </p>
                        <button className='github-repo'>
                            View on Github
                        </button>
                    </section>
                </Col>
            </>
        ) : (
            <div className='no-project-match'>No Result to display</div>
        ))
    }
    </>
  );
};

export default ProjectDetail;