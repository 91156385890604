
import { useEffect, useRef, useState } from 'react';
import './styles/ProgressRing.css';
import LanguageLogo from './LanguageLogo';

function ProgressRing({skill, fromApi}){
    const total = 378;

    const ringElementRef = useRef(null);
    const [arcOffset, setArcOffset] = useState(total);
    const [currentCounter, setCurrentCounter] = useState(0);

    useEffect(() => {
        if(currentCounter < skill.level){
            const animateRing = setTimeout(() => {
                setCurrentCounter(currentCounter + 1);
                setArcOffset((1 - (currentCounter/100)) * total);
            }, 10);
            return(() => {clearTimeout(animateRing)});
        }else if (currentCounter === skill.level) {
            setCurrentCounter(skill.level);
            setArcOffset((1 - (skill.level/100)) * total);
        } 
    }, [currentCounter, skill.level])

    // function that returns either the capitalized version of word or not
    // since i wanna display html and css all capital
    const capitalizeOrNot = (name) => {
        if (name === 'html' || name === 'css'){
            return name.toUpperCase();
        } else if (name === 'mongo') {
            return name.toUpperCase() + ' DB';
        } else if (name === 'node') {
            return name + 'JS';
        }
        return name;
    };

    return (
        <div className='item'>
            <div className='outer'>
                <div className='inner'> 
                    <div id='number'>
                        {currentCounter + ' %'}
                    </div>
                </div>
            </div>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="160px" height="160px">
                <defs>
                    <linearGradient id="GradientColor">
                        <stop offset="0%" stopColor="#e91e63" />
                        <stop offset="100%" stopColor="#673ab7" />
                    </linearGradient>
                </defs>

                <circle ref={ringElementRef} 
                id='circle' cx="80" cy="80" r="60" strokeLinecap="round" 
                strokeDashoffset={arcOffset}
                strokeDasharray={total}
                />
        
            </svg>
            <h5>
                {capitalizeOrNot(skill.language)}
            </h5>
            <div className='icon'>
                <LanguageLogo skill={skill} fromApi={fromApi} />
            </div>
        </div>
    );
};

export default ProgressRing;