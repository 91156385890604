import baseURL from "../config";


function LanguageLogo({skill, fromApi}){
    return (
        fromApi ?
        (<img src={`${baseURL}/static/images/${skill.toolImage}`} width='40px' alt='logo-language' />) :
        (<img src={require(`../assets/languages/${skill.language}.svg`)} width="40px" alt="logo-language" />)
    );
}

export default LanguageLogo;