import { Col, Container, Row } from 'react-bootstrap';
import './styles/Skills.css';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from '../assets/img/color-sharp.png';
import ProgressRing from './ProgressRing';

function Skills({skillsState}){
    let masteringLevels = [
        {'language' : 'php', 'level' : 84},
        {'language' : 'html', 'level' : 96},
        {'language' : 'laravel', 'level' : 85},
        {'language' : 'django', 'level' : 80},
        {'language' : 'docker', 'level' : 70},
        {'language' : 'bootstrap', 'level' : 75},
        {'language' : 'javascript', 'level' : 90},
        {'language' : 'mysql', 'level' : 92},
        {'language' : 'css' , 'level' : 70},
        {'language' : 'python', 'level' : 80},
        {'language' : 'react' , 'level' : 90},
        {'language' : 'redux', 'level' : 87},
        {'language' : 'git' , 'level' : 90},    
        {'language' : 'tailwind', 'level' : 85},
        {'language' : 'bash', 'level' : 81},
        {'language' : 'node', 'level' : 80},
        {'language' : 'dart', 'level' : 70},
    ]
    const responsive = {
        superLargeDesktop : {
            breakpoint : {max:4000, min:3000},
            items : 5
        },
        desktop : {
            breakpoint : {max:3000, min:1024},
            items :4
        },
        tablet : {
            breakpoint : {max:1024, min:464},
            items : 2
        },
        mobile : {
            breakpoint : {max:464, min:0},
            items :1
        }
    };
    return (
        <section className="skill" id="skills">
            <Container>
                <Row>
                    <Col>
                        <div className='skill-bx'>
                            <h2>Skills And Tools</h2>
                            <p>
                            As a versatile software engineer proficient in both frontend and backend development, 
                            my journey in the world of technology has been an exhilarating adventure. With a relentless 
                            curiosity and a passion for problem-solving, I have honed my skills in crafting dynamic and 
                            user-centric applications. From designing seamless user interfaces to building scalable and 
                            robust server-side architectures, I take pride in delivering innovative solutions that 
                            elevate user experiences.<br/>
                            This section is a glimpse into the toolkit I wield to transform ideas 
                            into reality. The array of programming languages, frameworks, and tools 
                            at my disposal empowers me to tackle a diverse range of projects with 
                            confidence. It is a testament to my commitment to staying at the forefront 
                            of technological advancements and embracing the ever-changing landscape of 
                            software engineering.
                            </p>
                            <Carousel responsive={responsive} infinite={true}
                            className="skill-slider">
                                { skillsState.skills.length > 0 ?
                                    (skillsState.skills.map(
                                        (element) =>  {
                                            return (<ProgressRing key={element.language} skill={element} fromApi={true} />);
                                        }
                                    )) :
                                    (
                                        masteringLevels.map((element) => {
                                            return (<ProgressRing key={element.language} skill={element} fromApi={false} />)
                                        })
                                    )
                                }
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </Container>
            <img className='background-image-left' src={colorSharp} alt="bg-color" />
        </section>
    )
};

export default Skills;