import { Col, Row } from 'react-bootstrap';
import React, {useRef} from 'react';
import './styles/SkillFileInput.css';

function SkillFileInput({onChangeInput, formData, isCreate}) {
    const svgFileInput = useRef(null);
    // function that handles opening the tab to choose a file
    const triggerFileSelect = (e) => {
        e.preventDefault();
        svgFileInput.current.click();
    };


  return (
    <Row className='skill-file-input'>
        <Col sm={12} className='d-flex justify-content-center'>
            <input type="file" id="toolImage" ref={svgFileInput} style={{display : 'none'}} 
            onChange={(e) => onChangeInput('toolImage', e.target.files[0])}/>
            <button className='select-file-button' onClick={triggerFileSelect}>
                <span>Select an SVG image</span>
            </button>
        </Col>
        <Col sm={12} className='d-flex justify-content-center'>
            <span className='p-3'> 
            {
                isCreate ? formData['toolImage'].name : formData['toolImage']
            }
            </span>
        </Col>
    </Row>
  )
}

export default SkillFileInput