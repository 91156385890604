

import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useRef } from 'react';
import './styles/FileInput.css';

function FileInput({file, onChange, errorsfile}) {
    // state that stores the name of the file 
    const [fileName, setFileName] = useState(null);
    const fileInputRef = useRef(null);

    // function that handles clicking the select image button 
    const handleSelectImageClick = (e) => {
        e.preventDefault();
        setFileName(file.name);
        fileInputRef.current.click();
    };

    // function that handles setting a new image
    const handleImageChange = (e) => {
        const selectedImage = e.target.files[0];
        selectedImage && setFileName(selectedImage.name);
        onChange('image', selectedImage);
    };

  return (
    <Row className='image-input'>
        <input type='file' name='image' ref={fileInputRef} 
        style={{display: 'none'}}
        onChange={handleImageChange}
        />
        <Col sm={12} md={6}>
            <button className='select-file-button' onClick={handleSelectImageClick}>
                <span>Select an Image</span>
            </button>
        </Col>
        <Col sm={12} md={6}>
            <span>{fileName ? fileName : file}</span>
        </Col>
        {
            errorsfile.length > 0 && (
                errorsfile.map((error) => {
                    return (
                        <Col key={error.name}>
                            <p className='form-error'>
                                {error.message}
                            </p>
                        </Col>
                    )
                })
            )
        }
    </Row>
    
  )
}

export default FileInput