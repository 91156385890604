
import AuthForm from '../components/AuthForm.js';
import { Col, Container, Row } from 'react-bootstrap';
import '../components/styles/Admin.css';
import background from '../assets/img/header-img.svg';
import { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../context/AuthContext.js';
function Admin(){
    const navigate = useNavigate();
    const {user} = useContext(AuthContext);
    // hook that checks if the user is authenticated 
    useEffect(() => {
        if(user){
            navigate('/projects');
        }
    }, [user])
    return (
        <div className="admin">
            <Container>
                <Row>
                    <Col sm={12} md={6} >
                        <img src={background} alt='bg' />
                    </Col>
                    <Col sm={12} md={6} className='d-flex align-items-center justify-content-center'>
                        <AuthForm />
                    </Col>
                </Row>
            </Container>
        </div>
    );
} 
export default Admin;