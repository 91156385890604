import { Col, Container, Nav, Tab, Row } from 'react-bootstrap';
import './styles/Projects.css';
import ProjectCard from './ProjectCard';
import colorSharp2 from '../assets/img/color-sharp2.png';
import blog from '../assets/projects/blog.avif';
import ecommerce from '../assets/projects/ecommerce.jpg';
import todos from '../assets/projects/todo.png';
import plants from '../assets/projects/plant.avif' ;
import { useContext, useEffect, useState } from 'react';
import { HomeAndProjectsContext } from './App';
function Projects(){
    // get projects data from Context
    const {projects} = useContext(HomeAndProjectsContext);

    let numberOfProjectsPerTab = 3;
    const indexesMapping = {
        0 : "first", 
        1 : "second",
        2 : "third",
        3 : "fourth",
        4 : "fifth",
        5 : "sixth",
        6 : "seventh",
        7 : "eighth",
        8 : "nineth", 
        9 : "tenth"
    };

    const getNumberCorrespondingTodigit = (num) => {
        switch (num) {
            case 0:
                return "One";
            case 1 :
                return "Two";
            case 2 : 
                return "Three";
            case 3 : 
                return "Four";
            case 4 :
                return "Five";
            default:
                return num;
        }
    }
    const myProjects = [
        {
            title : 'Blog Application',
            description : 'a laravel blog application allowing users to athenticate, register, verify their email, add posts and comments, with the full CRUD utilities included, authorization checks are also implemented',
            imgUrl : blog,
            gitUrl : "https://github.com/amineenim/Laravelblog"
        },
        {
            title : 'Online Store',
            description : "This is an E-Commerce User interface that allows the user to shop items from a shop that provides products from a fakestoreAPI, the app handles updating the global state using the Redux management state toolkit , the UI allows the user to click an item as many times as he likes, the item quantity, the total items in cart and the total price are updated in response, the user can also remove all items related to a product from cart and also to reset his cart, he has also the possibility to navigate between different pages and sort products by the desired category.",
            imgUrl : ecommerce ,
            gitUrl : "https://github.com/amineenim/E-Commerce-UI"
        },
        {
            title : "Todo App",
            description : "This application is a todo app that allows the user to planify their tasks, add new ones, delete them, edit them and finally mark a todo as completed, it also allows to keep todos even after page reload using localStorage to keep state between numerous reloads.",
            imgUrl : todos,
            gitUrl : "https://github.com/amineenim/TodoApp"
        },
        {
            title : "Plants Shop",
            description : "this is a project that diplays a UI for a plant shop with multiple utilities that make it interactive, a user may select any plants he'd like to buy and the total amount will be calculated in parallel and displayed for them, they can also hide the cart or show it with a button.",
            imgUrl : plants,
            gitUrl : "https://github.com/amineenim/plantsShopUI"
        }
    ];
    const [Tabs, setTabs] = useState(null);

    

    useEffect(() => {

        // function that computes the number of tabs based on the number of projects 
        const computeTabs = (projectsPerTab) => {
            let remainder = projects.length > 0 ? projects.length % projectsPerTab :  myProjects.length % projectsPerTab ;
            let TabsToCreate = 0;
            let array = [];
            TabsToCreate =  remainder === 0 ? Math.floor(projects.length > 0 ? projects.length / projectsPerTab : myProjects.length / projectsPerTab) : Math.floor(projects.length > 0 ? projects.length/projectsPerTab : myProjects.length / projectsPerTab) + 1 ;
            for( let i = 0; i < TabsToCreate; i++){
                array.push(i);
            }
            setTabs(array);
        };

        computeTabs(numberOfProjectsPerTab);
    }, [numberOfProjectsPerTab, myProjects.length, projects.length]);

    return (
        <section className='projects' id='projects'>
            <Container>
                <Row>
                    <Col>
                        <h2>Projects</h2>
                        <p>
                        Here, you will find a collection of some of my most exciting and innovative works. As a passionate and dedicated developer, 
                        I take immense pride in each project I undertake. With creativity as my driving force and attention to detail as my compass, 
                        I strive to deliver solutions that not only meet expectations but also exceed them.
                        you'll get a glimpse into the diverse range of projects I've had the pleasure of working on. 
                        From web development, APIs to design ventures and beyond, each project showcases my commitment 
                        to crafting seamless user experiences and visually captivating interfaces.
                        Whether it's the challenges of solving complex problems or the thrill of turning ideas into reality, 
                        every project has left an indelible mark on my journey as a developer.
                        I hope you enjoy exploring my projects as much as I enjoyed creating them. 
                        If you have any questions or would like to discuss potential collaborations, feel 
                        free to reach out. Let's build something incredible together!
                        </p>
                        <Tab.Container id="projects-tabs" defaultActiveKey="first">
                            <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                                {
                                    // loop to create as many tabs as necessary 
                                    Tabs && Tabs.map((element) => {
                                        return(
                                            <Nav.Item key={element}>
                                                <Nav.Link eventKey={indexesMapping[element]}>{getNumberCorrespondingTodigit(element)}</Nav.Link>
                                            </Nav.Item>
                                        )
                                    })
                                    
                                }
                            </Nav>
                            <Tab.Content>
                                {
                                    Tabs && Tabs.map((element) => {
                                        return(
                                            <Tab.Pane eventKey={indexesMapping[element]} key={element}>
                                                <Row>
                                                    {
                                                        projects.length > 0 ?
                                                        projects.map((pj, index) => {
                                                            if(index >= element * numberOfProjectsPerTab && index < (element + 1) * numberOfProjectsPerTab){
                                                                return (
                                                                    <ProjectCard key={index}
                                                                    title = {pj.title}
                                                                    description={pj.description}
                                                                    imgUrl={pj.image}
                                                                    gitUrl={pj.github_url}
                                                                    tools = {pj.tools}
                                                                    fromApi={true}
                                                                    />
                                                                )
                                                            }
                                                            return null;
                                                        })
                                                        :
                                                        myProjects.map((pj, index) => {
                                                            if(index >= element * numberOfProjectsPerTab && index < (element + 1) * numberOfProjectsPerTab){
                                                                return (
                                                                    <ProjectCard key={index}
                                                                    title={ pj.title }
                                                                    description={ pj.description }
                                                                    imgUrl={ pj.imgUrl }
                                                                    gitUrl={ pj.gitUrl }
                                                                    fromApi={false}
                                                                    />
                                                                );
                                                            }
                                                            return null;
                                                        })
                                                    }
                                                </Row>
                                            </Tab.Pane>
                                        )
                                    })
                                }
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className='background-image-right' src={colorSharp2} alt='bg-sharp-2' />
        </section>
    );
};

export default Projects;