

import React, { useEffect, useState } from 'react';
import './styles/ProjectForm.css';
import { Col, Row } from 'react-bootstrap';
import FileInput from './FileInput';
import { Link } from 'react-router-dom';
import baseURL from '../config';
import axios from 'axios';

function ProjectForm({submitText, isCreate, projectToEdit, setHasProjectsDataChanged, hasProjectsDataChanged}) {
    // maximun size for image
    const MAX_IMAGE_SIZE = 5 * 1024 * 1024;
    // state that stores whether the form data is valid , so it can be submitted to the backend
    const [isFormValid, setIsFormValid] = useState(true);
    // state that stores wheter data has been changed or not, for edit form 
    const [dataHasChanged, setDataHasChanged] = useState(false);
    // state that stores the message to display if data has been unchanged for edit form, or form is submitted while invalid
    const [submitFormdMessage, setSubmitFormdMessage] = useState('');
    // state that stores the keys where values has changed for edit form , since we re using PATCH request
    const [changedKeys, setChagedKeys] = useState([]);
    // state that stores any responses coming back from API call
    const [apiResponse, setApiResponse] = useState(null);
    // function that handles setting the initial data for the form 
    let formInitialData =  {
        'title' : "",
        'description' : "",
        'github' : "",
        'image' : ""
    };
    // state that stores data in the form 
    const [formData, setFormData] = useState(formInitialData);
    useEffect(() => {
        if(!isCreate && projectToEdit){
            formInitialData = {
                'title' : projectToEdit.title,
                'description' : projectToEdit.description,
                'github' : projectToEdit.github_url,
                'image' : projectToEdit.image
            }
            setFormData(formInitialData);
        };

    }, []);
         
    const onFormUpdate = (field, value) => {
        // set the data has changed
        if(!isCreate){
            setDataHasChanged(true);
        }
        setSubmitFormdMessage('');
        setFormData({...formData, [field] : value});
        validateForm(field, value);
        if(!isCreate){
            if(!changedKeys.some((elt) => elt === field)){
                setChagedKeys([...changedKeys, field]);
            }
        }
    };
   
    let formInitialErrors = {
        'title' : [],
        'description' : [],
        'github' : [],
        'image' : []
    }
  
    const [formErrors, setFormErrors] = useState(formInitialErrors);
    useEffect(() => {
        if(isCreate && hasProjectsDataChanged){
            formInitialErrors = {
                'title' : [{'name' : 'missingValue', 'message' : 'Title is required'}],
                'description' : [{'name' : 'missingValue', 'message' : 'Description is required'}],
                'github' : [{'name' : 'missingValue', 'message' : 'Github is required'}],
                'image' : []
            }
            setFormErrors(formInitialErrors);
            setIsFormValid(false);
        };
    }, [isCreate, hasProjectsDataChanged])

    // function that handles form submission
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        // if the form is an edit form 
        if(!isCreate){
            // get form data and check if it has changed, so the API call won't be executed unless data is modified
            if(dataHasChanged){
                if(isFormValid){
                    let dataToSubmit = {};
                    changedKeys.forEach((key) => {
                        dataToSubmit = {...dataToSubmit, [key] : formData[key]};
                    });
                    // make the API call
                    try {
                        const response = await axios.patch(`${baseURL}/update-project/${projectToEdit.id}`, dataToSubmit, {
                            headers : {
                                Accept : "application/json",
                                'Content-Type' : 'multipart/form-data',
                            }
                        });
                        if((response.status === 200 && response.statusText === 'OK') || response.status === 400){
                            // check for errors key in response 
                            if(response.data.errors){
                                setApiResponse({'success' : false, 'message' : 'form errors', 'errors' : response.data.errors});
                            }else {
                                setApiResponse({'success' : true, 'message' : response.data.message});
                                setHasProjectsDataChanged(true);
                            }
                        }
                        else{
                            setApiResponse({'success' : false, 'message' : 'Oops, something went wrong !'});
                        }
                    } catch (error) {
                        console.log(error.response);
                        console.log(error.response.data);
                    }
                    
                }else {
                    setSubmitFormdMessage('invalid form, check your provided data !');
                }
                
            }else {
                setSubmitFormdMessage('No changes for data, you can not submit form');
            } 
        }else {
            // the form corresponds to creating a new Project instance
            // check if form is valid
            if(!isFormValid){
                setSubmitFormdMessage('Invalid form, please check errors displayed and correct before submission');
                if(apiResponse){
                    setApiResponse(null);
                }
            }else {
                try {
                    const response = await axios.post(`${baseURL}/new-project/`, {
                        'title' : formData['title'],
                        'description' : formData['description'],
                        'github_url' : formData['github'],
                        'image' : formData['image']
                    }, {
                        headers : {
                            Accept : 'application/json',
                            'Content-Type' : 'multipart/form-data',
                        }
                    })
                    if(response.status === 200 && response.statusText === 'OK'){
                        if(response.data.errors){
                            console.log(response)
                            setApiResponse({'success' : false, 'message' : 'form errors', 'errors' : response.data.errors});
                        }else {
                            console.log('here');
                            setApiResponse({'success' : false, 'message' : response.data.message});
                        }
                    }
                    else if (response.status === 201){
                        setApiResponse(response.data);
                        setFormData(formInitialData);
                        setHasProjectsDataChanged(true);
                    }
                } catch (error) {
                    console.log(error);
                    console.log(error.response)
                    setApiResponse(error.response.data)
                }
            }

        }
    };

    
   
     // function that sets if the form is valid or not 
     useEffect(() => {
        const keys = Object.keys(formData);
        let founderrors = false;
        for(let i=0 ; i<keys.length ; i++){
            if(formErrors[keys[i]].length > 0){
                setIsFormValid(false);
                founderrors = true
                break;
            }
        }
        !founderrors && setIsFormValid(true);
    }, [formErrors])

    // function that validates form data 
    const validateForm = (field, value) => {
        if(field === 'title'){
            const regex = /^(?=.*[A-Za-z])[A-Za-z0-9-_\s]*$/;
            // check that the field is not empty 
            if(value === '' || value.trim() === ''){
                // check if the error doesn't exist already in the array 
                if(!formErrors['title'].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'missingValue', 'message' : 'Title is missing'}]});
                }
            }else {
                if(formErrors['title'].some((elt) => elt['name'] === 'missingValue')){
                    const newErrors = formErrors['title'].filter((elt) => elt['name'] !== 'missingValue');
                    setFormErrors({...formErrors, 'title' : newErrors});
                }
            }
            if(value.trim().length >= 70){
                // check if the error doesn't already exist 
                if(!formErrors['title'].some((elt) => elt['name'] === 'lengthExceeded')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'lengthExceeded' ,'message' :'The title must not exceed 70 characters'}]});
                }
            }else {
                if(formErrors['title'].some((elt) => elt['name'] === 'lengthExceeded')){
                    const newErrors = formErrors['title'].filter((elt) => elt['name'] !== 'lengthExceeded');
                    setFormErrors({...formErrors, 'title' : newErrors});
                }
            }
            if(value.trim().length < 10){
                if(!formErrors['title'].some((elt) => elt['name'] === 'lengthIncomplete')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'lengthIncomplete' ,'message' : 'Title must be at least 10 characters'}]});
                }
            }else {
                if(formErrors['title'].some((elt) => elt['name'] === 'lengthIncomplete')){
                    const newErrors = formErrors['title'].filter((elt) => elt['name'] !== 'lengthIncomplete');
                    setFormErrors({...formErrors, 'title' : newErrors});
                }
            }
            if(!regex.test(value)){
                if(!formErrors['title'].some((elt) => elt['name'] === 'regexError')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'regexError' , 'message' : "title must contain only characters, numbers, white spaces, - _ are also allowed"}]});
                }
            }else {
                if(formErrors['title'].some((elt) => elt['name'] === 'regexError')){
                    const newErrors = formErrors['title'].filter((elt) => elt['name'] !== 'regexError');
                    setFormErrors({...formErrors, 'title' : newErrors});
                }
            }
        }else if(field === 'description'){
            const regex = /^(?=.*[A-Za-z])[A-Za-z0-9-_\s,.'-]*$/;
            if(value === ''){
                if(!formErrors['description'].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'missingValue' ,'message' : 'Description is missing'}]});
                }
            }else {
                if(formErrors['description'].some((elt) => elt['name'] === 'missingValue')){
                    const newErrors = formErrors['description'].filter((elt) => elt['name'] !== 'missingValue');
                    setFormErrors({...formErrors, [field] : newErrors});
                }
            }

            if(value.trim().length >= 700){
                if(!formErrors['description'].some((elt) => elt['name'] === 'lengthExceeded')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'lengthExceeded' ,'message' : 'description must not exceed 700 characters'}]});
                }
            }else{
                if(formErrors['description'].some((elt) => elt['name'] === 'lengthExceeded')){
                    const newErrors = formErrors['description'].filter((elt) => elt['name'] !== 'lengthExceeded');
                    setFormErrors({...formErrors, [field] : newErrors});
                }
            }

            if(!regex.test(value)){
                if(!formErrors['description'].some((elt) => elt['name'] === 'regexError')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'regexError' ,'message' : 'description must ontain at least a charcter, characters , spaces, numbers , -, _ are allowed'}]});
                }
            }else {
                if(formErrors['description'].some((elt) => elt['name'] === 'regexError')){
                    const newErrors = formErrors['description'].filter((elt) => elt['name'] !== 'regexError');
                    setFormErrors({...formErrors, [field] : newErrors});
                }
            }

            if(value.trim().length < 80){
                if(!formErrors['description'].some((elt) => elt['name'] === 'lengthIncomplete')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field],{'name' : 'lengthIncomplete' ,'message' : 'description must be at least 80 characters'}]});
                }
            }else {
                if(formErrors['description'].some((elt) => elt['name'] === 'lengthIncomplete')){
                    const newErrors = formErrors['description'].filter((elt) => elt['name'] !== 'lengthIncomplete');
                    setFormErrors({...formErrors, [field] : newErrors});
                }
            }
        }else if(field === 'github'){
            const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
            if(value === '' || value.trim() === ''){
                if(!formErrors['github'].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'missingValue', 'message' : 'Url is missing'}]});
                }
            }else {
                if(formErrors['github'].some((elt) => elt['name'] === 'missingValue')){
                    const newErrors = formErrors['github'].filter((elt) => elt['name'] !== 'missingValue');
                    setFormErrors({...formErrors, 'github' : newErrors});
                }
            }
            if(!regex.test(value)){
                if(!formErrors['github'].some((elt) => elt['name'] === 'regexError')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'regexError' ,'message' : 'invalid URL'}]});
                }
            }else {
                if(formErrors['github'].some((elt) => elt['name'] === 'regexError')){
                    const newErrors = formErrors['github'].filter((elt) => elt['name'] !== 'regexError');
                    setFormErrors({...formErrors, 'github' : newErrors});
                }
            }
        
            
        }else if(field === 'image'){
            if(!value){
                if(!formErrors['image'].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'missingValue' ,'message' : 'No Image file selected'}]});
                }
            }else {
                if(formErrors['image'].some((elt) => elt['name'] === 'missingValue')){
                    const newErrors = formErrors['image'].filter((elt) => elt['name'] !== 'missingValue');
                    setFormErrors(newErrors);
                }
                if(!value.type.startsWith('image/')){
                    if(!formErrors['image'].some((elt) => elt['name'] === 'invalidFile')){
                        setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'invalidFile' ,'message' : 'Invalid image, only jpeg, png and jpg are accepted'}]});
                    }
                }else{
                    if(formErrors['image'].some((elt) => elt['name'] === 'invalidFile')){
                        const newErrors = formErrors['image'].filter(elt => elt['name'] !== 'invalidFile');
                        setFormErrors({...formErrors, [field] : newErrors});
                    }
                }
                if(value.size >= MAX_IMAGE_SIZE){
                    if(!formErrors['image'].some((elt) => elt['name'] === 'invalidSize')){
                        setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'invalidSize', 'message' : 'size of file exceeds 5MB, pick a smaller file' }]});
                    }
                }else{
                    if(formErrors['image'].some((elt) => elt['name'] === 'invalidSize')){
                        const newErrors = formErrors['image'].filter((elt) => elt['name'] !== 'invalidSize');
                        setFormErrors({...formErrors, [field] : newErrors});
                    }
                }
            }
        }
    }

    useEffect(() => {
        console.log(apiResponse);
    }, [apiResponse])

  return (
    <Col className='project-form'>
        {(submitFormdMessage )&& (
            <Row>
                <Col sm={12} className='data-unchanged-notification'> {submitFormdMessage} </Col>
            </Row>
        )}
        {
            (apiResponse) && (
                apiResponse.success ? (
                    <Row>
                        <Col sm={12} className='d-flex justify-content-center'>
                            <p className='success'> {apiResponse.message} </p>
                        </Col>
                    </Row>
                ) : (
                    apiResponse.message === 'form errors' ? (
                        <Row>
                            {
                                apiResponse.errors.map((error) => {
                                    // the field name corresponds to the key of the error object
                                    let keys = Object.keys(error);
                                    {
                                        error[keys[0]].map((err, index) => {
                                            return (
                                                <Col sm={12} key={index}>
                                                    <p className='danger'> {err} </p>
                                                </Col>
                                            )
                                        })
                                    }
                                })
                            }
                        </Row>
                    ) : (
                        <Row>
                            <Col sm={12}>
                                <p className='danger'> {apiResponse.message} </p>
                            </Col>
                        </Row>
                    )
                )
            )
        }
        <form encType='multipart/form-data' onSubmit={handleFormSubmit}>
            <Row>
            <Col sm={12} md={6}>
                <Row className='d-flex flex-column'>
                    <Col className='px-1'>
                        <input type='text' value={formData.title} 
                        name = 'title'
                        placeholder='Project Title'
                        onChange={(e) => onFormUpdate('title', e.target.value)}/>
                        {
                            formErrors['title'].length > 0 && (
                                formErrors['title'].map((error) => {
                                    return (
                                        <Col key={error.name}>
                                            <p className='form-error'>
                                                {error.message}
                                            </p>
                                        </Col>
                                    )
                                })
                            )
                        }
                    </Col>

                    <Col className='px-1'>
                        <input type='text' value={formData.github} 
                        name = 'github'
                        placeholder='Project Github URL'
                        onChange={(e) => onFormUpdate('github', e.target.value)}/>
                        {
                            formErrors['github'].length > 0 && (
                                formErrors['github'].map((error) => {
                                    return (
                                        <Col key={error.name}>
                                            <p className='form-error'>
                                                {error.message}
                                            </p>
                                        </Col>
                                    )
                                })
                            )
                        }
                    </Col>
                    
                    <FileInput file={formData.image} onChange={onFormUpdate} errorsfile = {formErrors['image']} />

                </Row>
            </Col>
                
            <Col sm={12} md={6}>
                <textarea rows="8" value={formData.description} 
                name='description'
                placeholder='Description' 
                onChange={(e) => onFormUpdate('description', e.target.value)} />
                {
                    formErrors['description'].length > 0 && (
                        formErrors['description'].map((error) => {
                            return (
                                <Col key={error.name}>
                                     <p className='form-error'>
                                        {error.message}
                                    </p>
                                </Col>
                            )
                        })
                    )
                }
            </Col>
            <Col sm={12} className='d-flex justify-content-center'>
                <button type='submit'>
                    <span>{submitText}</span>
                </button>
                {
                    !isCreate && (
                       
                        <Link to={'/projects'}>
                            <button>
                                <span>Cancel</span>
                            </button>
                        </Link>
                        
                    )
                }
            </Col>
            </Row>

        </form>
    </Col>
  )
}

export default ProjectForm