import { useContext, useEffect, useState } from 'react';
import './styles/AuthForm.css';
import baseURL from '../config';
import axios from 'axios';
import  {HomeAndProjectsContext}  from './App';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../context/AuthContext';
import jwtDecode from 'jwt-decode';
function AuthForm(){
    const navigate = useNavigate();
    // get data from context 
    const {setMessage} = useContext(HomeAndProjectsContext);
    // get the contextData
    const {setAuthTokens, setUser, authApiResponse, setAuthApiResponse} = useContext(AuthContext);
    // state that stores the value of the fields
    const [formData, setFormData] = useState({
        'username' : '',
        'password' : ''
    });
    // state that stores form errors if there are any
    const [formErrors, setFormErrors] = useState({
        'username' : [],
        'password' : []
    });
    
    // state that stores whether the form is valid or not 
    const [formIsValid, setFormIsValid] = useState(false);

    // state that stores response from API 
    const [apiResponse, setApiResponse] = useState(null);
    // the first time the user gets the form it has an empty errors array , the required field error doesn't show up
    // since it's triggered by an onChange event, so if the user never types something in fields this error is never set for the fields
    const [isUsernameFilled, setIsUsernameFilled] = useState(false);
    const [isPasswordFilled, setIsPasswordFilled] = useState(false);

    // function that handles setting formIsValid state each time the user changes something in the fields
    useEffect(() => {
        if(formErrors['username'].length === 0 && formErrors['password'].length === 0 && isUsernameFilled && isPasswordFilled){
            setFormIsValid(true);
        }else{
            setFormIsValid(false);
        }
    }, [formData, isUsernameFilled, isPasswordFilled])

     // function that handles authentication
    const handleAuthentication = async(e) => {
        e.preventDefault();
        if(!formIsValid){
            console.log('invalid form')
            if(!isUsernameFilled && !isPasswordFilled){
                setFormErrors({'username' : [{'name' : 'emptyValue', 'message' : 'Username is required'}], 'password' : [{'name' : 'emptyValue', 'message' : 'Password is required'}]})
            }else if(!isUsernameFilled){
                if(!formErrors['username'].some((elt) => elt['name'] === 'emptyValue')){
                    setFormErrors({...formErrors, 'username' : [...formErrors['username'], {'name' : 'emptyValue', 'message' : 'Username is required'}]});
                }
            }else{
                if(!formErrors['password'].some((elt) => elt['name'] === 'emptyValue') && !isPasswordFilled){
                    setFormErrors({...formErrors, 'password' : [...formErrors['password'], {'name' : 'emptyValue', 'message' : 'Password is required'}]});
                }
            }
        }
        else{
            // the form is valid proceed to API call
            console.log('form is valid');
            try {
                const response = await axios.post(`${baseURL}/users/token/`, formData, {
                    headers : {
                        'Accept' : 'application/json',
                        'Content-Type' : 'application/json'
                    }
                })
                if(response.status === 200 && response.statusText === 'OK'){
                    setAuthTokens(response.data);
                    setUser(jwtDecode(response.data.access));
                    localStorage.setItem('accessToken', response.data.access);
                    localStorage.setItem('refreshToken', response.data.refresh);
                    navigate('/projects');
                }
            } catch (error) {
                if(error.response.status === 401 && error.response.statusText === 'Unauthorized'){
                    setApiResponse({'success' : false, 'message' : "Provided credentials are incorrect, Check your credentials"});
                }else{
                    setApiResponse({'success' : false, 'message' : 'something went wrong !'});
                }
                console.log(error.response);
            }
        }     
    };

    // function that handles validating the form data 
    const validateFormData = (field, value) => {
        // if there's an apiResponse message display hide it, for example after a failed
        // authentication attempt when the user tries to enter new data
        apiResponse && setApiResponse(null);
        authApiResponse && setAuthApiResponse(null);
        const usernameRegex = /^[a-zA-Z0-9]*$/;
        if(field === 'username'){
            if(value === ''){
                setIsUsernameFilled(false);
                if(!formErrors['username'].some(elt => elt['name'] === 'emptyValue')){
                    setFormErrors({...formErrors, 'username' : [...formErrors['username'], {'name' : 'emptyValue', 'message' : 'Username is required'}]});
                }
            }else{
                setIsUsernameFilled(true);
                if(formErrors['username'].some((elt) => elt['name'] === 'emptyValue')){
                    const newFormErrors = formErrors['username'].filter((elt) => elt['name'] !== 'emptyValue');
                    setFormErrors({...formErrors, 'username' : newFormErrors})
                }
            }
            if(value.length >= 20){
                // check if the error does not already exist in the array
                if(!formErrors['username'].some((elt) => elt['name'] === 'maxLengthExceeded')){
                    setFormErrors({...formErrors, 'username' : [...formErrors['username'], {'name' : 'maxLengthExceeded', 'message' : "Username can't exceed 20 characters"}]});
                }
            }else{
                if(formErrors['username'].some((elt) => elt['name'] === 'maxLengthExceeded')){
                    const newFormErrors = formErrors['username'].filter(elt => elt['name'] !== 'maxLengthExceeded');
                    setFormErrors({...formErrors, 'username' : newFormErrors});
                }
            }
            if(!usernameRegex.test(value)){
                if(!formErrors['username'].some((elt) => elt['name'] === 'invalidRegex')){
                    setFormErrors({...formErrors, 'username': [...formErrors['username'], {'name' : 'invalidRegex', 'message' : 'Username must include only lowercase, uppercase characters and digits'}]});
                }
            }else{
                if(formErrors['username'].some((elt) => elt['name'] === 'invalidRegex')){
                    const newFormErrors = formErrors['username'].filter((elt) => elt['name'] !== 'invalidRegex');
                    setFormErrors({...formErrors, 'username' : newFormErrors});
                }
            }
            if(value.length < 8){
                if(!formErrors['username'].some((elt) => elt['name'] === 'incompleteLength')){
                    setFormErrors({...formErrors, 'username' : [...formErrors['username'], {'name' : 'incompleteLength', 'message' : 'username must be at least 8 characters'}]});
                }
            }else{
                if(formErrors['username'].some((elt) => elt['name'] === 'incompleteLength')){
                    const newFormErrors = formErrors['username'].filter((elt) => elt['name'] !== 'incompleteLength');
                    setFormErrors({...formErrors, 'username' : newFormErrors});
                }
            }
        } else{
            if(value === ''){
                setIsPasswordFilled(false);
                if(!formErrors['password'].some((elt) => elt['name'] === 'emptyValue')){
                    setFormErrors({...formErrors, 'password' : [...formErrors['password'], {'name' : 'emptyValue', 'message' : 'Password is required'}]});
                }
            }else {
                setIsPasswordFilled(true);
                if(formErrors['password'].some((elt) => elt['name'] === 'emptyValue')){
                    const newFormErrors = formErrors['password'].filter((elt) => elt['name'] !== 'emptyValue');
                    setFormErrors({...formErrors, 'password' : newFormErrors});
                }
            }
        }
    };
    // function that handles setting state when the user inputs something in the fields
    const handleChange = (field, value) => {
        // set the data for the corresponding field 
        setFormData({...formData, [field] : value});
        validateFormData(field, value);
    };

    return(
        <section className='auth-form d-flex flex-column'>
            <form>
                {
                    authApiResponse && (
                        <div>
                            {
                                authApiResponse.success ? 
                                <p className='success'> {authApiResponse.message} </p> :
                                <p className='danger'> {authApiResponse.message} </p>
                            }
                        </div>
                    )
                }
                {
                    apiResponse && (
                        <div>
                            {apiResponse.success ? 
                            <p className='success'> {apiResponse.message} </p> :
                            <p className='danger'> {apiResponse.message} </p>
                            }
                        </div>
                    )
                }
                <div>
                    <label htmlFor='username'>Username</label>
                    <input type='text' id='username' name='username' placeholder='Enter Your Username' 
                    onChange={(e) => handleChange('username', e.target.value)}
                    value={formData.username}/>
                    {
                        formErrors['username'].length > 0 && (
                            <ul className='auth-form-errors'>
                                {
                                    formErrors['username'].map(
                                        (error) => {
                                            return (
                                                <li key={error.name}><p className='danger'> {error.message} </p></li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                        )
                    }
                </div>
                <div>
                    <label htmlFor='password'>Password</label>
                    <input type='password' id='password' name='password' placeholder='Enter Password' 
                    onChange={(e) => handleChange('password', e.target.value)}
                    value={formData.password}/>
                    {
                        formErrors['password'].length > 0 && (
                            <ul className='auth-form-errors'>
                                {
                                    formErrors['password'].map(
                                        (error) => {
                                            return (
                                                <li key={error.name}>
                                                    <p className='danger'> {error.message} </p>
                                                </li>
                                            )
                                        }
                                    )
                                }
                            </ul>
                        )
                    }
                </div>
                <button type='submit' onClick={(e) => handleAuthentication(e)} >
                    <span>Submit</span>
                </button>
            </form>
        </section>
    )
}

export default AuthForm;