import React, { useContext, useEffect} from 'react'
import './styles/SharedLayout.css';
import { Col, Container, Row } from 'react-bootstrap';
import { Outlet, useNavigate } from 'react-router-dom';
import {HomeAndProjectsContext} from './App';
import GoBackButton from './GoBackButton';
import AuthContext from '../context/AuthContext';


function SharedLayout({forWhat}) {
    const navigate = useNavigate();
    // grab authentication data
    const {user} = useContext(AuthContext);
    //grab data from the Context
    const {projects, errors, message, setMessage, hasProjectsDataChanged, setHasProjectsDataChanged, skillsState} = useContext(HomeAndProjectsContext);
    // hook that checks the authentication status before allowing the component display
    useEffect(() => {
        if(!user){
            navigate('/admin');
        }
    }, [user]);

  return (
    <div className='projects-base'>
        <Container>
            <Row className='d-flex align-items-center'>
                <Col sm={12} className='d-flex justify-content-center'>
                    <h2>{forWhat} Management</h2>
                </Col>
                <Row sm={12} md={6} className='d-flex justify-content-center'>
                    <Col sm={12} md={6}>
                        <h4>Total {forWhat} : <span>
                            {
                                forWhat === "Projects" ? projects.length : skillsState.skills.length 
                            }
                        </span></h4>
                    </Col>
                    {forWhat === 'Projects' && (
                        <Col sm={12} md={6} className='d-flex justify-content-end'>
                            <GoBackButton text='Create new Project' targetsWhat='projects' isForCreate={true} message={message} 
                            setMessage={setMessage} 
                            hasProjectsDataChanged = {hasProjectsDataChanged}
                            setHasProjectsDataChanged = {setHasProjectsDataChanged} />
                        </Col>
                    )}
                    {forWhat === 'Skills' && (
                        <Col sm={12} md={6} className='d-flex justify-content-end'>
                            <GoBackButton text='Add new Skill' targetsWhat='skills' isForCreate={true} message={message}
                            setMessage={setMessage}/>
                        </Col>
                    )}
                </Row>
            </Row> 
            {
                errors && (
                    <Row className='d-flex flex-column'>
                        <Col sm={12} className='d-flex flex-column align-items-center'>
                            {
                                errors.map((error, index) => {
                                    return (
                                        <p className='danger' key={index}>
                                            <span>{error.status} : </span> {error.detail}
                                        </p>
                                    );
                                })
                            }
                        </Col>
                    </Row>
                )
            }
            {
                message && (
                    <Row>
                        {
                            message.success ? (
                                <p className='success'>{message.message}</p>
                            ) : (
                                <p className='danger'>{message.message}</p>
                            )
                        }
                    </Row>
                )
            }
            <Row>
                <Outlet />
            </Row>
        </Container>
    </div>
  );
};

export default SharedLayout