
import './styles/App.css';
import NavBar from './NavBar';
import Footer from './Footer';
import Home from '../pages/Home';
import Admin from '../pages/Admin';
import SharedLayout from './SharedLayout';
import ProjectsOrSkillsIndex from './ProjectsOrSkillsIndex';
import ProjectDetail from './ProjectDetail';
import ProjectEdit from './ProjectEdit';
import ProjectCreate from './ProjectCreate';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useState, createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import baseURL from '../config';
import { AuthProvider } from '../context/AuthContext';
import {NavigationProvider} from '../context/NavigationContext';
import SkillCreate from './SkillCreate';
import SkillEdit from './SkillEdit';
import SkillDetail from './SkillDetail';


// context that stores data shared between components Home and Projects related ones
export const HomeAndProjectsContext = createContext();

// constant that stores all the actions for the skillsState
export const SKILLS_ACTIONS = {
  SET_SKILLS_FROM_API : 'setSkillsFromApi',
  SET_SKILLS_DATA_CHANGED : 'setSkillsDataHasChanged',
}

function App() {
  // state that stores all the projects 
  const [projects, setProjects] = useState([]);
  //state that stores any messages returned from API
  const [message, setMessage] = useState(null);
  // state that stores errors during the API call
  const [errors, setErrors] = useState([]);
  // state that stores whether the projects data has changed, if a delete or edit was performed againt a project ressource 
  const [hasProjectsDataChanged, setHasProjectsDataChanged] = useState(false);

  // state that stores all ratings in array
  const [ratings, setRatings] = useState([]);
  // state that stores whether ratings has changed
  const [hasRatingsChanged, setHasRatingsChanged] = useState(false);

  // function that handles updating the skillsState
  const reducer = (skillsState, action) => {
    switch(action.type){
      case SKILLS_ACTIONS.SET_SKILLS_FROM_API :
        return {'skills' : action.payload.skills, 'hasSkillsDataChanged' : skillsState.hasSkillsDataChanged ? false : skillsState.hasSkillsDataChanged};
      case SKILLS_ACTIONS.SET_SKILLS_DATA_CHANGED :
        return {...skillsState, 'hasSkillsDataChanged' : true};
    };
  };

  const [skillsState, dispatch] = useReducer(reducer, {'skills' : [], 'hasSkillsDataChanged' : false})
  // function that handles the API call and grabing all projects
  useEffect(() => {
    const getAllProjects = async() => {
      try {
        const response = await axios.get(`${baseURL}/projects/`, {
          headers : {
            Accept : "application/json",
            "Content-Type" : "application/json"
          }
        });
        if(response.status === 200 && response.statusText === 'OK'){
          if (response.data.message){
            setMessage(response.data);
          } else {
            setProjects(response.data);
          }
        }else if (response.status === 400){
          setMessage(response.data);
        }
      } catch (error) {
        if(error.code === 'ERR_NETWORK' && error.message === 'Network Error'){
          setErrors([...errors, {'status' : error.code, 'detail' : error.message + ' Try again later.'}]);
        } else if (error.response.status === 404 && error.response.statusText === 'Not Found'){
          setErrors([...errors, {'status' : error.response.status, 'detail' : error.response.statusText + ', no backend endpoint matching'}]);
        } else if(error.response.status === 500 && error.response.statusText === 'Internal Server Error'){
          setErrors([...errors, {'status' : error.response.status, 'detail' : error.response.statusText + ', server down, try later'}]);
        } else {
          setErrors([...errors, {'status' : 400, 'detail' : 'somwthing went wrong'}])
        }
      }
    };
    getAllProjects();
  },[hasProjectsDataChanged]);

  // function that handles grabing skills from the API call
  const getAllSkills = async() => {
    try {
      const response = await axios.get(`${baseURL}/skills/`, {
        headers : {
          Accept : "application/json",
          "Content-Type" : "application/json"
        }
      })
      if(response.status === 200 && response.statusText === 'OK'){
        if(response.data.message){
          setMessage(response.data);
        }else if(response.data.skills){
          dispatch({type : SKILLS_ACTIONS.SET_SKILLS_FROM_API, payload : {skills : response.data.skills}});
        }
      }
    } catch (error) {
      if(error.response.status === 400 && error.response.statusText === 'Bad Request'){
        setErrors([...errors, {'status' : error.response.data.success, 'detail' : error.response.data.message }])
      }else{
        setErrors([...errors, {'status' : false, 'detail' : 'Something went wrong, try later'}]);
      }
    }
  };

  // function that handles grabing all ratings from API call
  const getAllRatings = async() => {
    try {
      const response = await axios.get(`${baseURL}/contact/ratings/`, {headers : {
          Accept : "application/json",
          'Content-Type' : "application/json"
      }});
      if(response.status === 200 && response.statusText === 'OK'){
          setRatings(response.data.ratings);
      }
  } catch (error) {
      console.log(error.response);
      if(error.code === 'ERR_NETWORK' && error.message === 'Network Error'){
        setErrors([...errors, {'status' : error.code, 'detail' : error.message + ' Try again later !' }]);
      }else if(error.response.status === 404 && error.response.statusText === 'Not Found'){
        setErrors([...errors, {'status' : error.response.status, 'detail' : error.response.statusText + ', No endpoint found' }]);
      }else if(error.response.status === 500 && error.response.statusText === 'Internal Server Error'){
        setErrors([...errors, {'status' : error.response.status, 'detail' : error.response.statusText + ', Try again later'}]);
      }else{
        setErrors([...errors, {'status' : error.response.status, 'detail' : error.response.statusText}]);
      }
  }}

  useEffect(() => {
    getAllRatings();
    hasRatingsChanged && setHasRatingsChanged(false);
  }, [hasRatingsChanged])

  // hook that's run the first time the component renders and whenever the hasSikllsDataChanged is true
  useEffect(() => {
    getAllSkills();
  }, [skillsState.hasSkillsDataChanged])


  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <NavigationProvider>
            <NavBar />
          </NavigationProvider>
          <HomeAndProjectsContext.Provider value={{projects, setProjects, errors, message, setMessage, setErrors, setHasProjectsDataChanged, hasProjectsDataChanged, skillsState, dispatch, ratings, setHasRatingsChanged}}>
            <Routes>
              <Route path='/' element = {<Home/>} />
              <Route path='/admin' element = {<Admin/>} />
              <Route path='/projects' element={<SharedLayout forWhat={"Projects"} />}>
                <Route index element={<ProjectsOrSkillsIndex forWhat={"Projects"} />} />
                <Route path=':id' element={<ProjectDetail />} />
                <Route path='new' element={<ProjectCreate />} />
                <Route path='edit/:id' element={<ProjectEdit />} />
              </Route>
              <Route path='/skills' element={<SharedLayout forWhat={"Skills"} />}>
                <Route index element={<ProjectsOrSkillsIndex forWhat={"Skills"} />} />
                <Route path='new' element={<SkillCreate/>} />
                <Route path='edit/:id' element={<SkillEdit />} />
                <Route path=':id' element={<SkillDetail />} />
              </Route>
            </Routes>
          </HomeAndProjectsContext.Provider>
          <Footer/>
        </AuthProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
