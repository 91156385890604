import React, { useContext, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap';
import GoBackButton from './GoBackButton';
import './styles/SkillEdit.css';
import { useParams } from 'react-router-dom';
import { HomeAndProjectsContext } from './App';
import SkillForm from './SkillForm';

function SkillEdit() {
    // state that stores the Skill To Edit 
    const [skillToEdit, setSkillToEdit] = useState(null);
    // get the id param from URL
    const {id} = useParams()
    // state that stores wether data is being loaded 
    const [isLoading, setIsLoading] = useState(true);
    // get data from context 
    const {skillsState, message, setErrors} = useContext(HomeAndProjectsContext);
    // hook that is run each time the id is changed to check if it corresponds to a given Skill and set the skillToEdit
    useEffect(() => {
        const getSkillToEdit = (skillId) => {
            if(skillsState.skills.length === 0 && !message){
                setIsLoading(true);
            }else{
                setIsLoading(false);
                const skill_searched = skillsState.skills.find((skill) => skill.id === skillId);
                if(skill_searched){
                    setSkillToEdit(skill_searched);
                }else{
                    console.log('passed heeere');
                    setErrors([{'status' : 404, 'detail' : "The given Id " + skillId + " does not correspond to a Skill"}]);
                }
            }
        };
        getSkillToEdit(parseInt(id, 10));
    }, [id, message, skillsState])

  return (
    <>
        <Row className='edit-skill-header'>
            <Col sm={12} md={6} >
                <h4>Edit Skill / Tool </h4>
            </Col>
            <Col sm={12} md={6} className='d-flex justify-content-end'>
                <GoBackButton targetsWhat="skills" text="Back to Skills" isForCreate={false} />
            </Col>
        </Row>
        {
            (!isLoading) &&(
                <Row className='d-flex justify-content-center'>
                    <Col sm={12}>
                        <SkillForm isCreate={false} submitText="Save Changes" skillToEdit={skillToEdit} />
                    </Col>
                </Row>
            )
        }
    </>
  )
};

export default SkillEdit;