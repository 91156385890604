
import Banner from '../components/Banner';
import Skills from '../components/Skills';
import Projects from '../components/Projects';
import Contact from '../components/Contact';
import Stars from '../components/Stars';
import { useContext } from 'react';
import { HomeAndProjectsContext } from '../components/App';

function Home(){
    const {skillsState} = useContext(HomeAndProjectsContext);
    return(
        <div className="home">
            <Banner />
            <Skills skillsState={skillsState} />
            <Projects />
            <Contact />
            <Stars />
        </div>
    );
}
export default Home;