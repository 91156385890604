import React, { useContext } from 'react';
import './styles/SkillDeleteConfirmation.css';
import baseURL from '../config';
import axios from 'axios';
import { HomeAndProjectsContext, SKILLS_ACTIONS } from './App';

function SkillDeleteConfirmation({skill, setIsBlurred, setIsDeleteShown}) {
    // get data from context 
    const {setMessage, dispatch, setErrors} = useContext(HomeAndProjectsContext);

    // function that handles when the user cancels the delete, he does not confirm it
    const handleCancelDelete = () => {
        setIsBlurred(false);
        setIsDeleteShown(false);
    }

    // function that handles targeting the API endpoint to delete a skill ressource
    const handleDeleteSkill = async() => {
        try {
            const response = await axios.delete(`${baseURL}/skills/delete/${skill.id}`, {
                headers : {
                    Accept : "application/json",
                    'Content-Type' : "application/json"
                }
            });
            if(response.status === 204 && response.statusText === 'No Content'){
                setMessage({'success' : true, 'message' : 'Skill ' + skill.language + ' deleted successefully '});
                setIsBlurred(false);
                setIsDeleteShown(false);
                dispatch({type : SKILLS_ACTIONS.SET_SKILLS_DATA_CHANGED});
            }
        } catch (error) {
            if(error.response.status === 404 && error.response.statusText === 'Not Found'){
                setErrors([{'status' : 404, 'detail' : 'unfound resource'}]);
            }else{
                setErrors([{'status' : 500, 'detail' : 'something went wrong'}]);
            }
            setIsBlurred(false);
            setIsDeleteShown(false);
        }
    };

  return (
    <div className='delete-confirmation'>
        <h3>Skill Delete Confirmation</h3>
        <p> Are you sure you want to delete the " {skill.language} " Skill ?</p>
        <div className='buttons-delete-skill'>
            <button onClick={handleDeleteSkill}><span>Confirm</span></button>
            <button onClick={handleCancelDelete} ><span>Cancel</span></button>
        </div>
    </div>
  )
}

export default SkillDeleteConfirmation