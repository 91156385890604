import { Col, Row } from 'react-bootstrap';
import './styles/ProjectEdit.css' ;
import GoBackButton from './GoBackButton';
import ProjectForm from './ProjectForm';
import React, { useEffect, useContext, useState } from 'react'
import { useParams } from 'react-router-dom';
import { HomeAndProjectsContext } from './App';

function ProjectEdit() {
    // state that stores the project to edit
    const [projectToEdit, setProjectToEdit] = useState(null);
    // function that handles getting the data for the project and prepopulate the form 
    const {id} = useParams();
    // get projects data from context
    const {projects, message, setErrors, setHasProjectsDataChanged} = useContext(HomeAndProjectsContext);
    // state that stores whether data has been loaded or not 
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        const getProjectToEdit = (projectId) => {
            if (projects.length ===0 && !message){
                setIsLoading(true);
            }else {
                setIsLoading(false);
                const project = projects.find(project => project.id === projectId);
                if(project){
                    setProjectToEdit(project);
                    setErrors([]);
                }else {
                    setErrors([{'status' : 404, 'detail' : 'No Project corresponds to the given query parameter of value ' + id}]);
                }
            }
        }
        getProjectToEdit(parseInt(id, 10));
    },[id, projects, message])
  return (
    <>
        <Row className='edit-project-header'>
            <Col sm={12} md={6}>
                <h4>Edit Project {projectToEdit ? projectToEdit.title : ''} </h4>
            </Col>
            <Col sm={12} md={6} className='d-flex justify-content-end'>
                <GoBackButton text="Back To Projects" isForCreate={false} targetsWhat='projects' />
            </Col>
        </Row>
        {
            (!isLoading) && (
                <Row className='d-flex justify-content-center'>
                    <ProjectForm isCreate={false} submitText="Save Changes" projectToEdit={projectToEdit} setHasProjectsDataChanged={setHasProjectsDataChanged} />
                </Row>
            )
        }
        
    </>
  )
}

export default ProjectEdit