import React, { useContext } from 'react'
import './styles/ProjectDeleteConfirmation.css';
import baseURL from '../config';
import axios from 'axios';
import { HomeAndProjectsContext } from './App';

export default function ProjectDeleteConfirmation({project, setIsBlurred, setIsDeleteShown}) {
    // get setMessage from context data
    const {setErrors, setMessage, setHasProjectsDataChanged} = useContext(HomeAndProjectsContext);
    // function that cancels the delete 
    const handleCancelDelete = () => {
        setIsDeleteShown(false);
        setIsBlurred(false);
    };

    // function that handles making the API call to delete a project
    const handleDeleteProject = async() => {
        try {
            const response = await axios.delete(`${baseURL}/delete-project/${project.id}`, {
                headers : {
                    Accept : 'application/json',
                    'Content-Type' : 'application/json'
                }
            })
            if(response.status === 204 && response.statusText === 'No Content'){
                setMessage({'message' : 'Project " ' +  project.title + '" deleted successefully', 'success' : true});
                setIsBlurred(false);
                setIsDeleteShown(false);
                setHasProjectsDataChanged(true);
            }
        } catch (error) {
            if(error.response.status === 400){
                setErrors([{'status' : 400, 'detail' : 'Bad request, somwthing went wrong'}]);
                console.log(error.response);
            }else {
                setErrors([{'status' : 'not 404', 'detail' : 'something went wrong', }]);
                console.log(error.response);
            }
            setIsBlurred(false);
            setIsDeleteShown(false);
        }

    };

  return (
    <div className='delete-confirmation'>
        <h3>Project Delete Confirmation</h3>
        <p>Are you sure you want to delete the project " {project.title} "</p>
        <div className='buttons-delete-project'>
            <button onClick={handleDeleteProject}><span>Confirm</span></button>
            <button onClick={handleCancelDelete}><span>Cancel</span></button>
        </div>
    </div>
  )
}
