import { createContext, useState } from 'react';

const NavigationContext = createContext();
export default NavigationContext;

export const NavigationProvider = ({children}) => {
    // state that stores the current activeLink for user
    const [activeLink, setActiveLink] = useState(() => localStorage.getItem('navigation') ? localStorage.getItem('navigation') : 'home');

    return (
        <NavigationContext.Provider value={{activeLink, setActiveLink}}>
            {children}
        </NavigationContext.Provider>
    );

};