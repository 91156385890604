
import { Col, Container, Row } from 'react-bootstrap';
import './styles/Stars.css';
import { useContext, useEffect, useState } from 'react';
import baseURL from '../config';
import axios from 'axios';
import { HomeAndProjectsContext } from './App';

function Stars(){
    const [rating, setRating] = useState(0);
    const [hovered, setHovered] = useState(0);
    // state that stores the message after a user has rated
    const [message, setMessage] = useState({'success' : false, 'message' : null});

    // get data from context
    const {ratings, setHasRatingsChanged} = useContext(HomeAndProjectsContext);

    // state that stores the average rating 
    const [averageRating, setAverageRating] = useState(0);

    // function that handles sending the rating to the backend
    const handleRating = async(newRating) => {
        // make the API call 
        try {
            const response = await axios.post(`${baseURL}/contact/rateme/`, {'value' : newRating}, {
                headers : {
                    Accept : "application/json",
                    'Content-Type' : "multipart/form-data"
                }});
            if(response.status === 201 && response.statusText === 'Created'){
                setMessage({'success' : response.data.success, 'message' : response.data.message});
                setHasRatingsChanged(true);
                // set the rating to 0 
                setRating(0);
            }
        } catch (error) {
            if(error.response.status === 400 && error.response.statusText === 'Bad Request'){
                setMessage({'success' : error.response.data.success, 'message' : error.response.data.message});
                setRating(0);
            }else{
                setMessage({'success' : false, 'message' : 'please Try again Later'});
                setRating(0);
                console.log(error.response);
            }
        }
    };
    // function that computes the ratings average each time the component renders, or ratings changes
    useEffect(() => {
        if(ratings.length > 0){
            let sumRatings = 0;
            for(let i =0; i<ratings.length ; i++){
                sumRatings += ratings[i].value;
            }
            setAverageRating((sumRatings / ratings.length).toFixed(2));
        }
    }, [ratings])


    // function that clears the message after 3s
    useEffect(() => {
        const resetMessage = setTimeout(() => {
            setMessage('');
        }, 3000);
        
        return () => {clearTimeout(resetMessage)};
    }, [message])

    return (
        <section className='stars-rating' id='stars'>
            <Container>
                <Row className='justify-content-center'>
                    <Col sm={12} md={6} className='text-rate-me'>
                        <h2>Rate Me</h2>
                        <p>
                        Thank you for visiting my portfolio! Your feedback is invaluable to me, 
                        as it helps me improve and provide a better user experience for future 
                        visitors. I would greatly appreciate it if you could take a moment to 
                        rate your experience with my portfolio using stars. Your rating will 
                        motivate me to continue growing as a developer, Feel free to be honest 
                        in your assessment, as constructive criticism is always welcome. Thank you 
                        for your time and for being a part of this journey with me!
                        </p>
                    </Col>
                    <Col sm={12} lg={6} className='rating'>
                        {
                            averageRating > 2 && <span className='average-rating'> {averageRating} <span className='star'>&#9733;</span> </span>
                        }
                        {
                            message.message && (
                                message.success ?
                                (
                                    <p className='success' sm={12}>
                                        {message.message}
                                    </p>
                                ) : 
                                (
                                    <p className='failure' sm={12}>
                                        {message.message}
                                    </p>
                                )
                                    
                            )
                        }
                        <div className='stars-container'>
                        {
                            
                            [...Array(5)].map((value, index) => {
                                index += 1;
                                return (
                                        <button type='button'
                                        key={index}
                                        className={index <= hovered ? 'on' : 'off'}
                                        onClick={() => setRating(index)}
                                        onMouseEnter={() => setHovered(index)}
                                        onMouseLeave={() => setHovered(rating)}
                                        >
                                            <span className='star'>&#9733;</span>
                                        </button>
                                );
                            })
                        }
                        </div>
                        {
                            rating > 0 && (
                                    <button className='submit-rating' type='button'
                                    onClick={() => handleRating(rating)}>Submit</button>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Stars;