import { Container, Row, Col } from 'react-bootstrap';
import './styles/Footer.css';
import instagran from '../assets/img/nav-icon3.svg';
import github from '../assets/img/github.svg';
import discord from '../assets/img/discord.svg';

function Footer(){
    return(
        <footer className='footer'>
            <Container>
                <Row className='align-items-center'>
                    <Col sm={6}>
                        <p className='logo'>Amine MAOURID</p>
                        <span>Full Stack Developer / Software Engineer</span>
                    </Col>
                    <Col sm={6} className="text-center text-sm-end">
                        <div className='social-icon'>
                            <a href="https://www.instagram.com/amine_maourid/"> <img src={instagran} alt="instagram"/> </a>
                            <a href="https://github.com/amineenim"> <img src={github} alt="github"/> </a>
                            <a href="#"> <img src={discord} alt="discord"/> </a>
                        </div>
                        <p>CopyRight 2023, All Rights Reserved</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;