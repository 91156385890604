

import React, { useContext, useState } from 'react'
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { HomeAndProjectsContext } from './App';
import { Link } from 'react-router-dom';
import ProjectDeleteConfirmation from './ProjectDeleteConfirmation';
import './styles/ProjectsOrSkillsIndex.css';
import baseURL from '../config';
import SkillDeleteConfirmation from './SkillDeleteConfirmation';


function ProjectsOrSkillsIndex({forWhat}) {
    // state that stores the project to delete 
    const [projectToDelete, setProjectToDelete] = useState(null);
    // state that stores the skill to delete
    const [skillToDelete, setSkillToDelete] = useState(null);
    // state that stores whether the delete pop up window is displayed or not , after clicking a delete button for a project
    const [isDeleteShown, setIsDeleteShown] = useState(false);
    // state that controls whether the background is blurred or not 
    const [isBlurred, setIsBlurred] = useState(false);
    // get data from context
    const {projects, errors, setMessage, hasProjectsDataChanged, setHasProjectsDataChanged, skillsState} = useContext(HomeAndProjectsContext);

    // function that handles showing the POP UP window to confirm delete 
    const handleDeletePopUp = (id) => {
        if(forWhat === 'Projects'){
            // get the project to delete 
            let project = projects.filter((project) => project.id === id);
            if(project){
                project = project[0];
            }
            setProjectToDelete(project);
            setIsBlurred(true);
            setIsDeleteShown(true);
            setMessage('');
        }else if(forWhat === 'Skills'){
            // get the skill to delete 
            let skill = skillsState.skills.filter((skill) => skill.id === id);
            if(skill.length > 0){
                skill = skill[0];
                setSkillToDelete(skill);
            }
            setIsBlurred(true);
            setIsDeleteShown(true);
        }
    };

  return (
    forWhat === 'Projects' ? 
    (<section className='projects-index'>
        {
            (projectToDelete && isDeleteShown) && (<ProjectDeleteConfirmation project={projectToDelete} setIsBlurred={setIsBlurred} setIsDeleteShown={setIsDeleteShown} />)
        }
        <div className={`all-projects ${isBlurred ? 'blurred-background' : ''}`}>
        <Col xs={12} className='table-responsive'>
            {
                projects.length > 0 ?
                (
                    <table className='table table-dark table-bordered table-lg w-100'>
                        <thead>
                            <tr>
                                <th scope='col'className='text-center' >Project Title</th>
                                <th scope='col' className='text-center'>Description</th>
                                <th scope='col' className='text-center'>Github repo</th>
                                <th scope='col' className='text-center'>Image</th>
                                <th scope='col' className='text-center'>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            { 
                                projects.map((project) => {
                                    return(
                                        <tr key={project.id}>
                                            <th scope='row'> {project.title.length > 20 ? project.title.substring(0, 19) + ' ...' : project.title} </th>
                                            <td> {project.description.length > 80 ? project.description.substring(0, 79) + '...' : project.description} </td>
                                            <td> {project.github_url} </td>
                                            <td> {project.image} </td>
                                            <td>
                                                <div className='buttons-project d-flex justify-content-between'>
                                                    <Link to={`/projects/${project.id}`} className='mx-1' onClick={() => setMessage('')}>
                                                        <FontAwesomeIcon icon={faEye} style={{position : 'relative', color : '#fff'}} size='lg' />
                                                    </Link>
                                                    <Link to={`/projects/edit/${project.id}`} className='mx-1' onClick={() => setMessage('')} >
                                                        <FontAwesomeIcon icon={faPen} style={{color: "#0dbc01", position: 'relative'}} size='lg' 
                                                        onClick={() => { hasProjectsDataChanged && setHasProjectsDataChanged(false) }}/>
                                                    </Link>
                                                    <button className='mx-1' onClick={() => handleDeletePopUp(project.id)} >
                                                        <FontAwesomeIcon icon={faTrash} style={{color : "#e82c35", position : 'relative'}} size='lg' 
                                                        onClick={() => { hasProjectsDataChanged && setHasProjectsDataChanged(false) }}/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                }) 
                            }
                        </tbody>
                    </table>
                ) : (
                    errors.length > 0 ? '' : (<span className='no-projects'>No Projects</span>)
                )
            }
        </Col>
        </div>
    </section>) :
    (
        <section className='skills-index'>
            {
                (skillToDelete && isDeleteShown) && <SkillDeleteConfirmation skill={skillToDelete} setIsBlurred={setIsBlurred} setIsDeleteShown={setIsDeleteShown} />
            }
            <div className={`all-skills ${isBlurred ? 'blurred-background' : ''}`}>
            <Col xs={12} className='table-responsive'>
                {
                    skillsState.skills.length > 0 ? 
                    (
                        <table className='table table-dark table-bordered table-lg w-100 text-center'>
                            <thead>
                                <tr>
                                    <th scope='col'>Language / Tool</th>
                                    <th scope='col'>Mastering Level</th>
                                    <th scope='col'>Logo</th>
                                    <th scope='col'>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    skillsState.skills.map((skill) =>{
                                        return (
                                            <tr key={skill.id}>
                                                <th scope='row'>{skill.language}</th>
                                                <td> {skill.level}</td>
                                                <td>
                                                    <img src={`${baseURL}/images/${skill.toolImage}`} style={{width : "30px"}} alt='logo-tool' />
                                                </td>
                                                <td>
                                                    <div className='buttons-project d-flex justify-content-center'>
                                                        <Link to={`/skills/${skill.id}`} className='mx-2'>
                                                            <FontAwesomeIcon icon={faEye} style={{position : 'relative', color : '#fff'}} size='lg' />
                                                        </Link>
                                                        <Link to={`/skills/edit/${skill.id}`} className='mx-2' onClick={() => setMessage('')} >
                                                            <FontAwesomeIcon icon={faPen} style={{color: "#0dbc01", position: 'relative'}} size='lg' 
                                                            />
                                                        </Link>
                                                        <button className='mx-2' onClick={() => handleDeletePopUp(skill.id)}>
                                                            <FontAwesomeIcon icon={faTrash} style={{color : "#e82c35", position : 'relative'}} size='lg' 
                                                            />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    ) :
                    (
                        errors.length > 0 ? '' : (<span className='no-skills'>No Skills for the moment</span>)
                    )
                }
            </Col>
            </div>
        </section>
    )
  );
}

export default ProjectsOrSkillsIndex;