import { Col } from "react-bootstrap";
import baseURL from "../config";
import hand from '../assets/img/hand.svg';


function ProjectCard({title, description, imgUrl, gitUrl, tools, fromApi}){
    
    return (
        <Col sm={6} md={6} lg={4}>
            <div className="project-imgbx">
                <img src={fromApi ? `${baseURL}/static${imgUrl}` : imgUrl} alt='bg-img' style={{ objectFit: 'cover', width: '100%', height: '100%' }}/>
                <div className="project-txtx">
                    
                    <h4>
                        {title}
                        <img src={hand} alt='hand-pointer' style={{width : '32px', marginLeft : '6px', opacity : 1 }} />
                    </h4>
                    <div className="used-tech">
                        <h5>Technologies Stack</h5>
                        <span className="tools"> {tools} </span>
                    </div>

                    <div className="project-details">
                        <a href={gitUrl}>
                            <button className="btn btn-primary">View on Github</button>
                        </a>
                        <span>{description}</span>
                    </div>
                    
                </div>
            </div>
        </Col>
    );
}

export default ProjectCard;