import React, { useContext } from 'react';
import './styles/ProjectCreate.css';
import { Row, Col } from 'react-bootstrap';
import GoBackButton from './GoBackButton';
import ProjectForm from './ProjectForm';
import { HomeAndProjectsContext } from './App';

function ProjectCreate() {
    const {hasProjectsDataChanged, setHasProjectsDataChanged} = useContext(HomeAndProjectsContext);
  return (
    <>
        <Row className='create-project-header'>
            <Col sm={12} md={6}>
                <h4>Create new Project</h4>
            </Col>
            <Col sm={12} md={6} className='d-flex justify-content-end'>
                <GoBackButton text="All Projects" isForCreate={false} targetsWhat="projects" />
            </Col>
        </Row>
        <Row>
            <ProjectForm isCreate={true} submitText='Create Project' 
            hasProjectsDataChanged = {hasProjectsDataChanged}
            setHasProjectsDataChanged={setHasProjectsDataChanged} />
        </Row>
    </>
  )
}

export default ProjectCreate