import React from 'react';
import { Row, Col } from 'react-bootstrap';
import './styles/SkillLevelSlider.css';

function SkillLevelSlider({onChangeInput, formData}) {

  return (
    <Row className='d-flex align-items-center justify-content-center m-0 p-0'>
        <Col sm={12} className='p-0'>
            <input type='range' id='level' min={0} max={100} step={1} value={formData['level']}
            onChange={(e) => onChangeInput('level', e.target.value)}/>
        </Col>
        <Col sm={12} className='d-flex justify-content-center'>
            <span className='sliderValue'> {formData['level']} % </span>
        </Col>
    </Row>
  )
}

export default SkillLevelSlider;