import React, {useContext, useEffect, useState} from 'react';
import './styles/SkillDetail.css';
import { HomeAndProjectsContext } from './App';
import { useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import GoBackButton from './GoBackButton';
import ProgressRing from './ProgressRing';
import baseURL from '../config';


function SkillDetail() {
    // get all skills from context 
    const {skillsState, setErrors, message} = useContext(HomeAndProjectsContext);
    // state that stores the Skill Detail to display
    const [skill, setSkill] = useState(null);
    // get the id of the Skill from the URL params
    const {id} = useParams();
    // state that stores whether data is Loading or not
    const [isLoading, setIsLoading] = useState(true);

    // hook that runs each time the component is rendered to get the skill corresponding to the id in URL params
    useEffect(() => {
        const getSkillById = (skill_id) => {
            if(skillsState.skills.length === 0 && !message){
                setIsLoading(true);
            }else{
                setIsLoading(false);
                if(skillsState.skills.length > 0){
                    const searched_skill = skillsState.skills.filter((sk) => sk.id === skill_id);
                    if (searched_skill.length > 0) {
                        setSkill(searched_skill[0]);
                        setErrors([]);
                    }else{
                        setErrors([{'status' : 404, 'detail' : "No skill corresponds to the value " + skill_id}]);
                    }
                }
            }
        };
        getSkillById(parseInt(id, 10));
    }, [id, skillsState.skills]);


  return (
    <>
    {
        isLoading ? 
        (
            <div className="loading">Data Is Loading</div>
        ) : 
        (
            skill ? (
                <>
                <Col sm={12} className="skill-detail-header">
                    <h3> Skill <span> {skill.language} </span> Details </h3>
                    <GoBackButton text="Go Back To Skills" targetsWhat="skills"/>
                </Col>
                <Col sm={12} className='skill-detail-body'>
                    <ProgressRing skill={skill} />
                </Col>
                </>
            ) : 
            (
                <div className='no-skill-match'>No Skill To Display</div>
            )
        )
    }
    </>
  )
}

export default SkillDetail