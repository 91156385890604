import { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowRightCircle } from 'react-bootstrap-icons';
import headerImg from '../assets/img/header-img.svg';

import './styles/Banner.css';

function Banner() {
    // state storing the index of the currently displayed text
    const [indexOfCurrent, setIndexOfCurrent] = useState(0);
    const descriptions = ["Full-stack Developer", "Software enthusiast"]
    // state that stores wheter the description is being deleted or typed
    const [isDeleting, setIsDeleting] = useState(false);
    // state that stores the portion of description that is displayed
    const [text, setText] = useState('');
    //
    const [delta, setDelta] = useState(300);
    const deleting_period = 200;
    
    useEffect(() => {
        let ticker = setInterval(() => {
            tick();
        }, delta)
        return () => { clearInterval(ticker) };
    },[text, isDeleting, delta]);

    const tick = () => {
        let i = indexOfCurrent % descriptions.length;
        let fullText = descriptions[i];
        if(isDeleting) {
            if(text === ''){
                setIsDeleting(false);
                setIndexOfCurrent(indexOfCurrent + 1);
                //setText(descriptions[i].substring(0, 1));
            }else {
                setDelta(deleting_period);
                let updatedText = fullText.substring(0, text.length -1);
                setText(updatedText);
            }
        } else {
            if(text === fullText){
                setIsDeleting(true);
                setText(fullText.substring(0, text.length -1));
            }else {
                let updatedText = fullText.substring(0, text.length + 1);
                setText(updatedText);
            }
        }
    };


    return (
        <section id="banner" className='banner'>
            <Container>
                <Row className="align-items-center">
                    <Col sm={12} lg={7}>
                        <span className='tagline'>Welcome to My Portfolio </span>
                        <h1>Hello World, I'am Amine,<br/> <span className='wrap'>{text}</span></h1>
                        <p>
                            I'm Amine ,  I'm a passionate full-stack developer and software enthusiast with 
                            a knack for crafting transformative applications.
                            my curiosity has driven me to master various programming languages and frameworks. 
                            I thrive on challenges, embracing complex problems and architecting elegant solutions.
                            As a full-stack developer, I excel in both front-end and back-end
                            development, and database management.
                            Beyond coding, I'm an avid learner, staying up-to-date with the latest trends and technologies. 
                            I actively seek opportunities to expand my skill set and embrace new tools for enhanced productivity.
                        </p>
                        <button onClick={() => console.log('clicked left')}>
                            Let's Connect <ArrowRightCircle size={25} />
                        </button>
                    </Col>
                    <Col sm={12} lg={5} style={{ overflow : 'hidden'}}>
                        <img src={headerImg} alt="header" style={{width : '100%', height : '100%'}} />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Banner;