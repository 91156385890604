
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { createContext, useState, useEffect } from 'react';
import baseURL from '../config'

const AuthContext = createContext();
export default AuthContext;

export const AuthProvider = ({children}) => {
    // state that stores the authenticated user 
    const [user, setUser] = useState(() => localStorage.getItem('accessToken') ? jwtDecode(localStorage.getItem('accessToken')) : null);
    // state that stores the authentication tokens
    const [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('accessToken') && localStorage.getItem('refreshToken')) ? {'access' : localStorage.getItem('accessToken'), 'refresh' : localStorage.getItem('refreshToken')} : null);
    // state that stores auth responses from API
    const [authApiResponse, setAuthApiResponse] = useState(null);
    // variable that defines the context data
    let contextData = {
        setUser : setUser,
        setAuthTokens : setAuthTokens,
        setAuthApiResponse : setAuthApiResponse,
        authApiResponse : authApiResponse,
        user : user,
    }

    // function that handles updating the tokens each five minutes
    const updateTokens = async() => {
        try {
            const response = await axios.post(`${baseURL}/users/token/refresh/`,{"refresh" : authTokens.refresh}, {
                headers : {
                    'Content-Type' : "application/json",
                    "Accept" : "application/json",
                }
            });
            if(response.status === 200 && response.statusText === "OK"){
                setAuthTokens(response.data);
                setUser(jwtDecode(response.data.access));
                localStorage.setItem('accessToken', response.data.access);
                localStorage.setItem('refreshToken', response.data.refresh);
            }else{
                console.log(response.data);
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                setAuthTokens(null);
                setUser(null);
            }
        } catch (error) {
            if(error.response.status === 400 && error.response.statusText === 'Bad Request'){
                // either no refresh token was submitted or submitted with an empty value
                setAuthApiResponse({'success' : false, 'message' : 'something went wrong'});
            }else if(error.response.status === 401 && error.response.statusText === 'Unauthorized'){
                setAuthApiResponse({'success' : false, 'message' : error.response.data.detail + ' Authenticate again' });
            }else{
                setAuthApiResponse({'success' : false, 'message' : 'something went wrong, Try later'});
            }
            console.log(error.response)
            console.log(error.response.data);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            setAuthTokens(null);
            setUser(null);
        }
    };

    useEffect(() => {
        const fourMinutes = 4 *60 * 1000 ;

        const interval = setInterval(() => {
            if(authTokens){
                updateTokens();
            }
        }, fourMinutes);

        return () => clearInterval(interval);
    }, [authTokens])

    return(
        <AuthContext.Provider value={contextData}>
            {children}
        </AuthContext.Provider>
    )
}
