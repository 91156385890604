import React, { useContext, useEffect, useState } from 'react';
import './styles/SkillForm.css';
import { Col, Container, Row } from 'react-bootstrap';
import SkillLevelSlider from './SkillLevelSlider';
import SkillFileInput from './SkillFileInput';
import baseURL from '../config';
import axios from 'axios';
import { SKILLS_ACTIONS } from './App';
import { HomeAndProjectsContext } from './App';
import { Link } from 'react-router-dom';

function SkillForm({isCreate, submitText, skillToEdit}) {
    // get data from Context API
    const {dispatch, skillsState} = useContext(HomeAndProjectsContext);
    // state that stores the form data 
    const formInitialData = {
        'language' : '',
        'level' : 0,
        'toolImage' : ''
    }
    const [formData, setFormData] = useState(formInitialData);
    // hook that sets the form initial data depending on the form, is it for creating or editing
    useEffect(() => {
        if(!isCreate && skillToEdit){
            setFormData({
                'language' : skillToEdit.language,
                'level' : skillToEdit.level,
                'toolImage' : skillToEdit.toolImage,
            });
            console.log(skillToEdit);
        }

    }, [isCreate, skillToEdit]);

    // form initial errors, since when the form is for creating , at first it must have errors for required fields
    let formInitialErrors = {
        'language' : [],
        'level' : [],
        'toolImage' : []
    }
    // state that stores validation errors for the form 
    const [formErrors, setFormErrors] = useState(formInitialErrors);

    // state that stores whether the form is valid or not
    const [isFormValid, setIsFormValid] = useState(false);

    // state that stores formMessage after submit if the form is submitted while invalid
    const [submitFormMessage, setSubmitFormMessage] = useState('');

    // state that stores unchanged fields, which are submitted empty in the create form
    const [untouchedFields, setUntouchedFields] = useState(['language', 'level', 'toolImage']);

    // state that stores responses from API call 
    const [apiResponse, setApiResponse] = useState(null);

    // state that stores whether the form data has changed , when the form is for updating
    const [skillDataHasChanged, setSkillDataHasChanged] = useState(false);
    //state that stores the fields names where data has changed, for edit form
    const [changedFields, setChangedFields] = useState([])

    // function that validates the field values
    const validateData = (field, value) => {
        apiResponse && setApiResponse(null);
        if (field === 'language'){
            const languageRegex = /^(?=.*[a-zA-Z])([a-zA-Z0-9]*)$/;
            //remove from untouched fields
            isCreate && setUntouchedFields(untouchedFields.filter(f => f !== 'language'));

            if(!languageRegex.test(value)){
                //check if the error does not already exist
                if(!formErrors[field].some((elt) => elt['name'] === 'invalidRegex') && value !== ''){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : "invalidRegex", 'message' : "invalid expression , only a-z, A-Z, 0-9 are allowed"}]});
                }
            }else{
                // check if the error exists and remove it 
                if(formErrors[field].some((elt) => elt['name'] === 'invalidRegex')){
                    const newErrorsForField = formErrors[field].filter((elt) => elt['name'] !== 'invalidRegex')
                    setFormErrors({...formErrors, [field] : newErrorsForField});
                }
            }
            if(value === ''){
                // check if the error does not already exist before adding it
                if(!formErrors[field].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'missingValue', 'message' : 'Language or tool is required'}]});
                }
            }else{
                //check if the missingValue error exists and remove it 
                if(formErrors[field].some((elt) => elt['name'] === 'missingValue')){
                    const newErrorsForField = formErrors[field].filter((elt) => elt['name'] !== 'missingValue');
                    setFormErrors({...formErrors, [field] : newErrorsForField});
                }
            }
            if(value.length >= 30){
                // check if the error already exists in the array before adding it 
                if(!formErrors[field].some((elt) =>  elt['name'] === 'exceededLength' )){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : 'exceededLength', 'message' : 'the field can not exceed 30 characters'}]});
                }
            }else{
                //check if the error exists and remove it 
                if(formErrors[field].some((elt) => elt['name'] === 'exceededLength')){
                    const newErrorsForField = formErrors[field].filter((elt) => elt['name'] !== 'exceededLength');
                    setFormErrors({...formErrors, [field] : newErrorsForField});
                }
            }
        }else if(field === 'level'){
            //remove from untouched fields
            isCreate && setUntouchedFields(untouchedFields.filter(f => f !== 'level'));

            if(value === '' || value === '0'){
                if(!formErrors[field].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : "missingValue", 'message' : "the level is required"}]});
                }
            }else{
                // check if the error exists and remove it 
                if(formErrors[field].some((elt) => elt['name'] === 'missingValue')){
                    const newErrorsForField = formErrors[field].filter((elt) => elt['name'] !== 'missingValue');
                    setFormErrors({...formErrors, [field] : newErrorsForField});
                }
            }
        }else if(field === 'toolImage'){
            //remove from untouched fields
            isCreate && setUntouchedFields(untouchedFields.filter(f => f !== 'toolImage'));

            if(!value){
                console.log('passed here');
                if(!formErrors[field].some((elt) => elt['name'] === 'missingValue' )){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : "missingValue", 'message' : "the image is required"}]});
                }
            }else{
                if(formErrors[field].some((elt) => elt['name'] === 'missingValue')){
                    setFormErrors({...formErrors, [field] : formErrors[field].filter((elt) => elt['name'] !== 'missingValue')});
                }
            }
            if(value.type !=='image/svg+xml'){
                if(!formErrors[field].some((elt) => elt['name'] === 'invalidFile')){
                    setFormErrors({...formErrors, [field] : [...formErrors[field], {'name' : "invalidFile", "message" : "The submitted File format is invalid"}]});
                }
            }else{
                if(formErrors[field].some((elt) => elt['name'] === 'invalidFile')){
                    const newErrorsForField = formErrors[field].filter((elt) => elt['name'] !== 'invalidFile');
                    setFormErrors({...formErrors, [field] : newErrorsForField});
                }
            }
        }
    }

    // function that handles changing the value of an input on the form
    const onChangeInput = (field, value) => {
        // if the form is for update , set skillDataHasChanged to true
        if(!isCreate){
            setSkillDataHasChanged(true);
        }
        // set the new value to formData 
        setFormData({...formData, [field] : value});
        validateData(field, value);
        // set the message generated while trying to submit an invalid form to ''
        setSubmitFormMessage('');
        if(!isCreate){
            if(!changedFields.some(changedField => changedField === field)){
                setChangedFields([...changedFields, field]);
            }
        }
    };

    // hook that is run each time the formErrors has changed to check if the form is valid or not 
    useEffect(() => {
        let keys = Object.keys(formData);
        console.log(formErrors);
        let founderrors = false;
        for(let i=0; i<keys.length ; i++){
            if(formErrors[keys[i]].length > 0){
                setIsFormValid(false);
                founderrors = true;
                break;
            }
        }
        if(isCreate){
            !founderrors && untouchedFields.length === 0 && setIsFormValid(true);
        }else{
            !founderrors && setIsFormValid(true);
        }
    },[formErrors, untouchedFields, isCreate])
 
    //function that handles the submission of the skillForm
    const handleSkillFormSubmit = async(e) => {
        e.preventDefault();   
        if(isCreate){
            if(!isFormValid){
                setSubmitFormMessage('Invalid form, please check the errors before submitting the Form !');
                if(isCreate && untouchedFields.length > 0){
                    let errors_if_empty = formInitialErrors
                    untouchedFields.forEach((field) => {
                        errors_if_empty = {...errors_if_empty, [field] : [{"name" : "missingValue", "message" : "The " + field + " is required"}]};
                    })
                    setFormErrors(errors_if_empty);
                }
            }else{
                console.log(formData);
                try {
                    const response = await axios.post(`${baseURL}/skills/new/`, formData, {
                        headers : {
                            Accept : "application/json",
                            "Content-Type" : "multipart/form-data"
                        }
                    })
                    if(response.status === 201 && response.statusText === 'Created'){
                        setApiResponse({'success' : response.data.success, 'message' : response.data.message});
                        setFormData({
                            'language' : '',
                            'level' : 0,
                            'toolImage' : ''
                        });
                        dispatch({type : SKILLS_ACTIONS.SET_SKILLS_DATA_CHANGED});
                    }
                } catch (error) {
                    if(error.response.status === 400 && error.response.statusText === 'Bad Request'){
                        if(error.response.data.errors){
                            setApiResponse({'success' : error.response.data.success, 'message' : error.response.data.message, 'errors' : error.response.data.errors});
                        }
                    }else {
                        setApiResponse(error.response.data);
                        console.log(error.response.data);
                    }
                }
            }
        } else {
            // this corresponds to the update form 
            if(skillDataHasChanged){
                if(!isFormValid){
                    setSubmitFormMessage("invalid Form, please check errors before submission !");
                }else{
                    // the form is valid we must only submit the values that have been changed
                    let data_to_submit = {}
                    changedFields.forEach((field) => {
                        data_to_submit = {...data_to_submit, [field] : formData[field]}
                    });
                    console.log(data_to_submit);
                    //make the API call 
                    try {
                        const response = await axios.patch(`${baseURL}/skills/edit/${skillToEdit.id}`,data_to_submit, {
                            headers : {
                                Accept : 'application/json',
                                'Content-Type' : "multipart/form-data"
                            }
                        });
                        if(response.status === 200 && response.statusText === 'OK'){
                            setApiResponse({'success' : response.data.success, 'message' : response.data.message});
                            // the API call should be made again to bring new data 
                            dispatch({type : SKILLS_ACTIONS.SET_SKILLS_DATA_CHANGED});
                        }
                    } catch (error) {
                        if(error.response.status === 404 && error.response.statusText === 'Not Found'){
                            setApiResponse({'success' : error.response.data.success, 'message' : error.response.data.message});
                        }else if(error.response.status === 400 && error.response.statusText === 'Bad Request'){
                            setApiResponse({'success' : error.response.data.success, 'message' : 'invalid data submitted !', 'errors' : error.response.data.errors});
                        }else{
                            setApiResponse({'success' : false, 'message' : 'Something Went wrong !'});
                        }
                    }
                }
            }else{
                setSubmitFormMessage('Form data has been unchanged !');
            }
        }  
    };

  return (
    <Container>
        <Row>
            {submitFormMessage && (
                <Col sm={12}>
                    <p className='invalid-form-notification text-center'> {submitFormMessage} </p>
                </Col>
            )}
        </Row>
        {
            (apiResponse) && (
                apiResponse.success ? (
                    <Row>
                        <Col>
                            <p className='success'> {apiResponse.message} </p>
                        </Col>
                    </Row>
                ) : (
                    apiResponse.message === 'submitted data is invalid' ? (
                        <Row>
                            {
                                apiResponse.errors.forEach((error) => {
                                    let keys = Object.keys(error);
                                    error[keys[0]].map((err, index) => {
                                        return (
                                            <Col sm={12} key={index}>
                                                <p className='danger'> {err} </p>
                                            </Col>
                                        )
                                    })
                                })
                            }
                        </Row>
                    ) : (
                        <Row>
                            <Col sm={12}>
                                <p className='danger'> {apiResponse.message} </p>
                            </Col>
                        </Row>
                    )
                )
            )
        }
        <Row className='d-flex justify-content-center'>
            <Col sm={12} lg={6} className='skill-form'>
                <form encType='multipart/form-data' className='d-flex' onSubmit={handleSkillFormSubmit}>
                    <Row className='mb-4'>
                        <Col sm={12}>
                            <label htmlFor='language' className='mb-2'>Language/Tool Name : </label>
                            <input type='text' name='language' id='language'
                            placeholder='Language or Tool'
                            value={formData['language']}
                            onChange={(e) => onChangeInput('language', e.target.value)}
                            />
                        </Col>
                        {
                            formErrors['language'].length > 0 && (
                                formErrors['language'].map((error) => {
                                    return (
                                        <Col key={error.name} sm={12} className='text-center'>
                                            <p className='form-error'>{error.message}</p>
                                        </Col>
                                    )
                                })
                            )
                        }
                        <Col sm={12}>
                            <label htmlFor='level'>Mastering Level :</label>
                            <SkillLevelSlider onChangeInput={onChangeInput} formData={formData} />
                        </Col>
                        {
                            formErrors['level'].length > 0 && (
                                formErrors['level'].map((error) => {
                                    return(
                                        <Col key={error.name} sm={12} className='text-center'>
                                           <p className='form-error'>{error.message}</p>
                                        </Col>
                                    )
                                })
                            )
                        }
                        <Col sm={12}>
                            <SkillFileInput onChangeInput={onChangeInput} formData={formData} isCreate={isCreate}  />
                        </Col>
                        {
                            formErrors['toolImage'].length > 0 && (
                                formErrors['toolImage'].map((error) => {
                                    return (
                                        <Col key={error.name} sm={12} className='text-center'>
                                            <p className='form-error'>{error.message}</p>
                                        </Col>
                                    )
                                })
                            )
                        }
                        <hr/>
                        <Col sm={12} className='d-flex justify-content-center'>
                            <button type='submit'>
                                <span> {submitText} </span>
                            </button>
                            {
                                !isCreate && (
                                    <Link to={`/skills`}>
                                        <button>
                                            <span>Cancel</span>
                                        </button>
                                    </Link>
                                )
                            }
                        </Col>
                    </Row>
                </form>
            </Col>
        </Row>
    </Container>
  )
}

export default SkillForm