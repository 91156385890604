

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faPlus } from '@fortawesome/free-solid-svg-icons';
import './styles/GoBackButton.css';
import { Link } from 'react-router-dom';

function GoBackButton({text,targetsWhat, isForCreate, message, setMessage, hasProjectsDataChanged, setHasProjectsDataChanged}) {
  // function that handles the click on create new project
  // it sets the message to empty string if there's a message 
  // it also sets hasProjectsDataChanged to false if it is true
  const handleClickToCreateProject = () => {
    if(isForCreate) {
      message && setMessage('');
      hasProjectsDataChanged && setHasProjectsDataChanged(false);
    }
  };
  const handleClickToCreateSkill = () => {
    if(isForCreate){
      message && setMessage('');
    }
  };

  return (
    targetsWhat === 'projects' ?
    (
      <Link to={ isForCreate ? `/projects/new` : `/projects`} className='go-back' onClick={handleClickToCreateProject}>
          {
            isForCreate ? (
              <FontAwesomeIcon icon={faPlus} size='lg' style={{position : 'relative'}} />
            ) : (<FontAwesomeIcon icon={faArrowLeft} size='lg' style={{ position : 'relative'}} />)
          }
          <span>
              {text}
          </span>
      </Link>) : 
    (
      <Link to={ isForCreate ? `/skills/new` : `/skills`} className='go-back' onClick={handleClickToCreateSkill}>
          {
            isForCreate ? (
              <FontAwesomeIcon icon={faPlus} size='lg' style={{position : 'relative'}} />
            ) : (<FontAwesomeIcon icon={faArrowLeft} size='lg' style={{ position : 'relative'}} />)
          }
          <span>
              {text}
          </span>
      </Link>
    )
  )
}

export default GoBackButton;