import React from 'react';
import './styles/SkillCreate.css';
import { Col, Row } from 'react-bootstrap';
import GoBackButton from './GoBackButton';
import SkillForm from './SkillForm';

function SkillCreate() {
  return (
    <>
        <Row className='create-skill-header'>
            <Col sm={12} md={6}>
                <h4>Create New Skill / Add Tool</h4>
            </Col>
            <Col sm={12} md={6} className='d-flex justify-content-end'>
                <GoBackButton text="All Skills" isForCreate={false} targetsWhat="skills" />
            </Col>
        </Row>
        <Row>
            <SkillForm isCreate={true} submitText="Create Skill / Tool"  />
        </Row>
    </>
  )
}

export default SkillCreate