import { Col, Container, Row } from 'react-bootstrap';
import './styles/Contact.css';
import contactImg from '../assets/img/contact-img.svg';
import { useEffect, useState } from 'react';
import baseURL from '../config';
import axios from 'axios';

function Contact(){
    const formInitialData = {
        firstName : '',
        lastName : '',
        email : '',
        phone : '',
        message : ''
    }
    // state that stores form data 
    const [formData, setFormData] = useState(formInitialData);
    // state that stores text on the submit button 
    const [submitText, setSubmitText] = useState('send')
    // state that stores the status , based on the API call return
    const [status, setStatus] = useState({});
    // state that stores whether the user has already clicked the submit button, to display initialErrors for the form 'required' ones
    const [hasClickedSubmit, setHasClickedSubmit] = useState(false);
    // state that stores validation errors for the form 
    let formInitialErrors = {
        'firstName' : [{'type' : "missingValue", 'message' : "First Name is required *"}],
        'lastName' : [{'type' : "missingValue", "message" : "Last Name is required *"}],
        'email' : [{'type' : "missingValue", "message" : "Email address is required *"}],
        'phone' : [{'type' : "missingValue", "message" : "Phone Number is required *"}],
        'message' : [{'type' : "missingValue", "message" : "Message is required *"}]
    }
    const [formErrors, setFormErrors] = useState(formInitialErrors);
    // state that stores if form is valid 
    const [formIsValid, setFormIsValid] = useState(false);
    // function that validates fields of the form 
    const validateField = (field, value) => {
        if (field === 'firstName' || field === 'lastName'){
            let nameRegex = /^[a-zA-Z]{1,}$/;
            let newErrorsField = formErrors[field];
            // check if the field is empty 
            if(value === ''){
                //if the error messages for the firsName does not include already 'missingValue' add it 
                if(!formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = [{'type' : 'missingValue', 'message' : field === 'firstName' ? 'FirstName is required *' : 'LastName is required *'}]
                    //setFormErrors({...formErrors, [field] : [{'type' : 'missingValue', 'message' : 'First Name is required *'}]});
                }
            }else{
                // check if the missingValue error exists for the field and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'missingValue');
                    //setFormErrors({...formErrors, [field] : newErrorsField});
                }
                // check for minimal length 
                if(value.length < 4){
                    // if the minimalLength error is not set already add it
                    if(!formErrors[field].some((elt) => elt['type'] === 'minimalLength')){
                        newErrorsField = [...newErrorsField, {'type' : 'minimalLength', 'message' : field === 'firstName' ? "First Name must be at least 4 characters" : "Last Name must be at least 4 characters"}];
                        //setFormErrors({...formErrors, [field] : [...formErrors[field], {'type' : 'minimalLength', 'message' : "First Name must be at least 4 characters"}]});
                    }
                }else{
                    if(formErrors[field].some((elt) => elt['type'] === 'minimalLength')){
                        newErrorsField = newErrorsField.filter((elt) => elt['type'] !== 'minimalLength');
                        //setFormErrors({...formErrors, [field] : newErrorsField});
                    }
                    // check for maximal length 
                    if(value.length > 10){
                        if(!formErrors[field].some((elt) => elt['type'] === 'maximalLength')){
                            newErrorsField = [...newErrorsField, {'type' : 'maximalLength', 'message' : field === 'firstName' ? 'First Name can not exceed 10 characters' : "Last Name can not exceed 10 characters"}];
                            //setFormErrors({...formErrors, [field] : [...formErrors[field], {'type' : 'maximalLength', 'message' : 'First Name can not exceed 10 characters'}]});
                        }
                    }else{
                        if(formErrors[field].some((elt) => elt['type'] === 'maximalLength')){
                            newErrorsField = newErrorsField.filter((elt) => elt['type'] !== 'maximalLength');
                            //setFormErrors({...formErrors, [field] : newErrorsField});
                        }
                    }
                }
                
            }
            
            // check for regex
            if(!nameRegex.test(value)){
                // check if invalidRegex error is not already set for this field
                if(!formErrors[field].some((elt) => elt['type'] === 'invalidRegex') && !newErrorsField.some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = [...newErrorsField, {'type' : 'invalidRegex', 'message' : "invalid value, only lower or upper case characters"}]
                    //setFormErrors({...formErrors, [field] : [...formErrors[field], {'type' : 'invalidRegex', 'message' : "invalid value, only lower or upper case charcters, minimum 4 !"}]});
                }
            }else{
                // regex is valid, check if the error invalidRegex exists and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'invalidRegex')){
                    newErrorsField = newErrorsField.filter((elt) => elt['type'] !== 'invalidRegex');
                    //const newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'invalidRegex');
                    //setFormErrors({...formErrors, [field] : newErrorsField});
                }
            }
            setFormErrors({...formErrors, [field] : newErrorsField});
        }
        if(field === 'email'){
            let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            let newErrorsField = formErrors[field];
            // check if the field is empty
            if(value === ''){
                // check if 'missingValue' error is not already set for the field
                if(!formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = [{'type' : 'missingValue', 'message' : 'Email Address is required *'}];
                }
            }else{
                // if 'missingValue' error is already set for the field, remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'missingValue');
                }
            }
            if(emailRegex.test(value)){
                // check if 'invalidRegex' error is already set for the field and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'invalidRegex')){
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'invalidRegex');
                }
            }else{
                // check if 'invalidRegex' error does not exist and add it 
                if(!formErrors[field].some((elt) => elt['type'] === 'invalidRegex')){
                    newErrorsField = [...newErrorsField, {'type' : 'invalidRegex', 'message' : 'invalid email address'}];
                }
            }
            setFormErrors({...formErrors, [field] : newErrorsField});
        }
        if(field === 'phone'){
            let newErrorsField = formErrors['phone'];
            let phoneRegex = /^\d{10}$/;
            if(value === ''){
                // check if 'missingValue' error does not exist already for the field and add it
                if(!formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = [{'type' : 'missingValue', 'message' : 'Phone number is required *'}];
                }
            }else{
                // check if 'missingValue' error exists for the field and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'missingValue');
                }
            }
            if(phoneRegex.test(value)){
                // check if invalidRegex error is already set for the field and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'invalidRegex')){
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'invalidRegex');
                }
            }else{
                // check if the error 'invalidRegex' is not already existing and add it 
                if(!formErrors[field].some((elt) => elt['type'] === 'invalidRegex')){
                    newErrorsField = [...newErrorsField, {'type' : 'invalidRegex', 'message' : 'Invalid Phone number, it must contain 10 digits'}];
                }
            }
            setFormErrors({...formErrors, [field] : newErrorsField});
        }
        if(field === 'message'){
            let newErrorsField = formErrors['message'];
            let messageRegex = /^[a-zA-Z0-9,.' ]+$/;
            if(value === ''){
                // check if 'missingValue' error is not already set for the field and add it 
                if(!formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = [{'type' : 'missingValue', 'message' : 'Message is required *'}];
                }
            }else{
                // check if error 'missingValue' already exists and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'missingValue')){
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'missingValue');
                }
                // check for minimal length 
                if(value.trim().length <= 30){
                    // check if 'minimalLength' error is not already set and add it 
                    if(!formErrors[field].some((elt) => elt['type'] === 'minimalLength')){
                        newErrorsField = [...newErrorsField, {'type' : 'minimalLength', 'message' : 'Message must be at least 30 characters long'}];
                    }
                }else{
                    // check if 'minimalLength' is already set and remove it 
                    if(formErrors[field].some((elt) => elt['type'] === 'minimalLength')){
                        newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'minimalLength');
                    }
                    // check for maximal length 
                    if(value.trim().length >= 255){
                        // check if 'maximalLength' error is not already set and add it 
                        if(!formErrors[field].some((elt) => elt['type'] === 'maximalLength')){
                            newErrorsField = [...newErrorsField, {'type' : 'maximalLength', 'message' : 'Message can not exceed 255 characters'}];
                        }
                    }else{
                        // check if 'maximalLength' is already set and remove it 
                        if(formErrors[field].some((elt) => elt['type'] === 'maximalLength')){
                            newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'maximalLength');
                        }
                    }
                }
            }
            if(!messageRegex.test(value)){
                // check if 'invalidRgex' does not exist and add it 
                if(!newErrorsField.some((elt) => elt['type'] === 'missingValue') && !formErrors[field].some((elt) => elt['type'] === 'invalidRegex'))
                {
                    newErrorsField = [...newErrorsField, {'type' : 'invalidRegex', 'message' : 'Invalid text, only characters lowercase/upercase, periods, commas and spaces are allowed'}];
                }
            }else{
                // check if 'invalidRegex' exists and remove it 
                if(formErrors[field].some((elt) => elt['type'] === 'invalidRegex'))
                {
                    newErrorsField = formErrors[field].filter((elt) => elt['type'] !== 'invalidRegex');
                }
            }
            setFormErrors({...formErrors, [field] : newErrorsField});
        }
        
    }
    // function that handles the form changing
    const onFormUpdate = (category, value) => {
        status && setStatus({});
        validateField(category, value);
        setFormData(
           { ...formData,
            [category] : value}
        );
    };

    // function that handles setting if the form is valid or not
    useEffect(() => {
        let keys = Object.keys(formErrors);
        let founderrors = false;
        for(let i=0; i<keys.length ; i++){
            if(formErrors[keys[i]].length > 0){
                founderrors = true;
                setFormIsValid(false);
                break;
            }
        }
        !founderrors && setFormIsValid(true);

    },[formErrors])


    // function that handles hiding the success text displayed ater a user succesefully contacts Me 
    useEffect(() => {
        let timerId;
        if ( status && status.success){
            timerId =  setTimeout(() => setStatus(null), 3000)
        }
        return (() => clearTimeout(timerId));
    }, [status, setStatus]);

    // function that handles the form submission
    const handleSubmit = async(e) => {
        e.preventDefault();
        // the user has clicked submit 
        setHasClickedSubmit(true);
        if(!formIsValid){
            setStatus({'success' : false, 'message' : 'Invalid form, please make corrections before submit !'});
        }else{
            console.log('form is valid');
            console.log(formData);
            // make the API call to send the message 
            try {
                const response = await axios.post(`${baseURL}/contact/new/`, {
                    'firstName' : formData['firstName'],
                    'lastName' : formData['lastName'],
                    'email' : formData['email'],
                    'phone' : formData['phone'],
                    'message' : formData['message']
                }, {
                    headers : {
                        Accept : 'application/json',
                        "Content-Type" : 'multipart/form-data'
                    }});
                if(response.status === 201 && response.statusText === 'Created'){
                    // empty the form 
                    setFormData(formInitialData);
                    setStatus({
                        success : response.data.success,
                        message : response.data.message
                    });
                }
            } catch (error) {
                console.log(error.response);
                if(error.response.status === 400 && error.response.statusText === 'Bad Request'){
                    setStatus({
                        success : error.response.data.success,
                        message : error.response.data.message
                    });
                    console.log(error.response.data.errors);
                }else if(error.response.status === 406 && error.response.statusText === 'Not Acceptable'){
                    console.log(error.response);
                    setStatus({
                        success : false,
                        message : 'something Went wrong !'
                    })
                }
                else{
                    setStatus({
                        success : false,
                        message : 'something went wrong, try later please !'
                    })
                }
            }
        }
    };

    return (
        <section className='contact' id='connect'>
            <Container>
                <Row className="align-items-center">
                    <Col md={6}>
                        <img src={contactImg} alt='contact=bg' />
                    </Col>
                    <Col md={6}>
                        <h2>Get In Touch With Me</h2>
                        <form onSubmit={handleSubmit}>
                            <Row>
                                {
                                    (status && status.message) && 
                                    <Col sm={12} className='flex justify-center py-3'>
                                        <p className={status.success ? 'success' : 'danger'}>
                                            {status.message}
                                        </p>
                                    </Col>
                                }
                                <Col sm={6} className='px-1'>
                                    <input type='text' value={formData.firstName} 
                                    placeholder='First Name'
                                    name="firstName"
                                    onChange={(e) => onFormUpdate('firstName', e.target.value)}/>
                                    {
                                        (formErrors['firstName'] && formErrors['firstName'].length > 0 && hasClickedSubmit) &&
                                        (
                                            
                                            formErrors['firstName'].map((error) => {
                                                return (
                                                    <Col className='px-2 py-1' key={error.type}>
                                                        <p className='error-msg'> {error.message} </p>
                                                    </Col>
                                                )
                                            })
                                            
                                        )
                                    }
                                </Col>
                                <Col sm={6} className='px-1'>
                                    <input type='text' value={formData.lastName} 
                                    placeholder='Last Name'
                                    name='lastName'
                                    onChange={(e) => onFormUpdate('lastName', e.target.value)}/>
                                    {
                                        (formErrors['lastName'] && formErrors['lastName'].length > 0 && hasClickedSubmit) &&
                                        (
                                            formErrors['lastName'].map((error) => {
                                                return (
                                                    <Col className='px-2 py-1' key={error.type}>
                                                        <p className='error-msg'> {error.message} </p>
                                                    </Col>
                                                )
                                            })
                                        )
                                    }
                                </Col>
                                <Col sm={6} className='px-1'>
                                    <input type='email' value={formData.email} 
                                    placeholder='Email Address'
                                    name='email'
                                    onChange={(e) => onFormUpdate('email', e.target.value)}/>
                                    {
                                        (formErrors['email'] && formErrors['email'].length > 0 && hasClickedSubmit) &&
                                        (
                                            formErrors['email'].map((error) => {
                                                return (
                                                    <Col className='px-2 py-1' key={error.type}>
                                                        <p className='error-msg'> {error.message} </p>
                                                    </Col>
                                                )
                                            })
                                        )
                                    }
                                </Col>
                                <Col sm={6} className='px-1'>
                                    <input type='tel' value={formData.phone} 
                                    placeholder='Phone Number'
                                    name='phone'
                                    onChange={(e) => onFormUpdate('phone', e.target.value)}/>
                                    {
                                       ( formErrors['phone'] && formErrors['phone'].length > 0 && hasClickedSubmit) &&
                                        (
                                            formErrors['phone'].map((error) => {
                                                return (
                                                    <Col className='px-2 py-1' key={error.type}>
                                                        <p className='error-msg'> {error.message} </p>
                                                    </Col>
                                                )
                                            })
                                        )
                                    }
                                </Col>
                                <Col sm={12}>
                                    <textarea rows="6" value={formData.message} 
                                    placeholder='message' 
                                    name='message'
                                    onChange={(e) => onFormUpdate('message', e.target.value)} />
                                    {
                                        (formErrors['message'] && formErrors['message'].length > 0 && hasClickedSubmit) &&
                                        (
                                            formErrors['message'].map((elt) => {
                                                return (
                                                    <Col className='px-2 py-1' key={elt.type}>
                                                        <p className='error-msg'> {elt.message} </p>
                                                    </Col>
                                                )
                                            })
                                        )
                                    }
                                    <button type='submit'>
                                        <span>{submitText}</span>
                                    </button>
                                </Col>
                            </Row>
                        </form>
                    </Col>
                </Row>
            </Container>
        </section>
    );
;
}

export default Contact;